import {
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import MaterialSkeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import { Thumbnail, UnsafeThumbnail } from "src/components/shows";
import {
  AgeRating as AgeRatingType,
  AiringSchedule,
  AiringStatus,
  FeaturedProp,
  Show,
} from "src/generated/graphql";
import { notEmpty, repeat } from "src/utils";
import { HfSection } from "src/components/global/HfSection";
import { AllDetails } from "./components/AllDetails";
import {
  CustomTab,
  SwipeableTabs,
  useDocumentTitle,
} from "src/components/global";
import { ShowPlayground } from "src/sections/ShowPlayground";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PlatformIcon } from "src/components/global/PlatformIcon";
import { getNextEpisodeText } from "./hooks/useNextEpisodeAiring";
import { useEffect, useRef, useState } from "react";
import { ShowsList } from "src/sections/HomePage/components/ShowsList";
import { DateTime } from "luxon";
import { useIsAuthenticated } from "src/config";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(6, 0),

    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(3, 3),
    },
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  subtitle: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  description: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  nextAiringEpisode: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: `${theme.palette.background.paper}20`,
  },
  titleWrapper: {
    width: "calc(100% - 185px)",

    [theme.breakpoints.up("xl")]: {
      width: "calc(100% - 250px)",
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  thumbnailWrapper: {
    display: "flex",
    justifyContent: "center",
    width: 185,

    // [theme.breakpoints.up("xl")]: {
    //   width: 250,
    // },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  thumbnail: {
    borderRadius: theme.custom.thumbnail.borderRadius,
  },
  tagsWrapper: {
    padding: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  tagsContainer: {
    marginBottom: theme.spacing(),
  },
  tag: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
  tabBox: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  show: Show | null;
  nextAiringEpisode: AiringSchedule | null;
  loading: boolean;
  refetching: boolean;
}

export function ShowDetails({
  show,
  nextAiringEpisode,
  loading,
  refetching,
}: Props) {
  useDocumentTitle({ title: show ? show.title : "Loading..." });
  const classes = useStyles();

  const getEpisodesCount = () => {
    if (show) {
      return show.episodesCount > 0 ||
        (show.status && show.status !== AiringStatus.Current)
        ? show.episodesCount
        : nextAiringEpisode?.episodeNumber || 0;
    }

    return nextAiringEpisode?.episodeNumber || 0;
  };

  const showExtraBadge =
    show &&
    show.status &&
    [
      AiringStatus.ComingSoon,
      AiringStatus.Current,
      AiringStatus.Unreleased,
      AiringStatus.Upcoming,
    ].includes(show.status);

  const extraBadgeMarkup = showExtraBadge && show && show.status && (
    <Chip label={show.friendlyStatus} />
  );

  if (loading || !show) {
    return <ShowDetailsSkeleton />;
  }

  return (
    <Container maxWidth="lg" className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9}>
          <Grid container spacing={2}>
            <Grid item className={classes.thumbnailWrapper}>
              <Thumbnail hideTitle show={show} />
            </Grid>
            <Grid item className={classes.titleWrapper}>
              <Typography variant="h4" className={classes.title}>
                {show.title} {extraBadgeMarkup}
              </Typography>
              <Typography variant="subtitle1" className={classes.subtitle}>
                {show.titleRecord.jp}
              </Typography>
              <Typography variant="overline">
                {show.startsOn ? new Date(show.startsOn).getFullYear() : "N/A"}
              </Typography>
              <br />
              <Typography variant="caption">
                <AgeRating ageRating={show.ageRating} />
              </Typography>
              <Grid item xs={12} sm={6} md={8} lg={9} style={{ marginTop: 16 }}>
                <div style={{ padding: 10 }}>
                  <Grid container spacing={1}>
                    {show.links.map((link) => (
                      <Grid item key={link.urlType}>
                        <a href={link.value} target="_blank" rel="noreferrer">
                          {link.platform && (
                            <PlatformIcon
                              platform={link.platform}
                              height={60}
                              width={60}
                            />
                          )}
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ paddingTop: 16, paddingBottom: 16 }}
          >
            <Grid item xs={12}>
              <SwipeableTabsForShow
                show={show}
                nextAiringEpisode={nextAiringEpisode}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <AllDetails
            show={show}
            refetching={refetching}
            episodesCount={getEpisodesCount()}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

function SwipeableTabsForShow({
  show,
  nextAiringEpisode,
}: {
  show: Show;
  nextAiringEpisode?: AiringSchedule | null;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const timerInitialized = useRef(false);
  const authenticated = useIsAuthenticated();
  const isInvalidPlaygroundBreakpoint = useMediaQuery(
    theme.breakpoints.down("sm")
  );
  const hidePlayground = !authenticated || isInvalidPlaygroundBreakpoint;
  const defaultTimeUntilText = nextAiringEpisode
    ? getNextEpisodeText(nextAiringEpisode.timeUntilAiring, true, true)
    : "";

  const [timeUntilText, setTimeUntilText] = useState(defaultTimeUntilText);
  const [timeUntilAiring, setTimeUntilAiring] = useState(
    nextAiringEpisode?.timeUntilAiring || -1
  );

  useEffect(() => {
    if (nextAiringEpisode) {
      setTimeUntilAiring(nextAiringEpisode.timeUntilAiring);
      timerInitialized.current = true;
    }
  }, [nextAiringEpisode]);

  useEffect(() => {
    if (!timerInitialized.current) {
      return;
    }

    const timer = setInterval(() => {
      if (timeUntilAiring && timeUntilAiring > 0) {
        setTimeUntilAiring(timeUntilAiring - 1);
        setTimeUntilText(getNextEpisodeText(timeUntilAiring, true, true));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeUntilAiring]);

  const episodeCount =
    nextAiringEpisode?.episodeNumber && show.episodesCount ? (
      <span>
        <strong style={{ color: theme.palette.secondary.main }}>
          {nextAiringEpisode?.episodeNumber}
        </strong>
        /{show.episodesCount}
      </span>
    ) : show?.episodesCount > 0 ? (
      String(show?.episodesCount)
    ) : nextAiringEpisode?.episodeNumber ? (
      <strong style={{ color: theme.palette.secondary.main }}>
        {nextAiringEpisode?.episodeNumber}
      </strong>
    ) : (
      <div />
    );

  const airingAtDate = show.airingAt ? DateTime.fromISO(show.airingAt) : null;

  const basicInfoMarkup = (
    <>
      {nextAiringEpisode && airingAtDate && (
        <HfSection>
          <Paper variant="outlined" className={classes.nextAiringEpisode}>
            <Grid container style={{ textAlign: "center" }}>
              <Grid item xs={6} md={4}>
                <Typography variant="h6">Coming up next:</Typography>
                <Typography>Episode {episodeCount}</Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography variant="h6">Airing on:</Typography>
                <Typography>
                  {airingAtDate.toLocaleString(DateTime.DATETIME_FULL)}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="h6">In about...</Typography>
                <Typography>{timeUntilText}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </HfSection>
      )}
      <ShowDescription show={show} />
    </>
  );

  const tabs: CustomTab[] = [
    {
      tabProps: { label: "Basic info" },
      body: basicInfoMarkup,
      defaultOn: "general",
    },
    show.relatedShows.length > 0
      ? {
          tabProps: { label: `Related shows (${show.relatedShows.length})` },
          body: (
            <ShowsList
              title="Discover those shows!"
              shows={show.relatedShows}
              showPropsAsBadge={[
                FeaturedProp.Year,
                FeaturedProp.FriendlyStatus,
              ]}
              xs={6}
              sm={4}
              md={4}
              lg={3}
            />
          ),
          defaultOn: "related",
        }
      : null,
    !hidePlayground
      ? {
          tabProps: { label: "Playground" },
          body: <ShowPlayground show={show} />,
          defaultOn: "playground",
        }
      : null,
  ].filter(notEmpty);

  return <SwipeableTabs tabs={tabs} renderAsLeast={2} />;
}

function ShowDescription({ show }: { show: Show }) {
  const classes = useStyles();
  const isMusic = show.showCategory === "music";

  const trailerMarkup = show.youtubeTrailerUrl && (
    <HfSection title={isMusic ? "Watch now" : "Trailer"}>
      <iframe
        title={`Trailer for ${show.title}`}
        width="100%"
        height="500px"
        src={show.youtubeTrailerUrl}
      ></iframe>
    </HfSection>
  );

  const descriptionMarkup =
    isMusic && show.youtubeTrailerUrl ? (
      <Paper variant="outlined" className={classes.description}>
        <Typography variant="body2">{show.description}</Typography>
      </Paper>
    ) : (
      <HfSection title="Description">
        <Paper variant="outlined" className={classes.description}>
          <Typography variant="body2">{show.description}</Typography>
        </Paper>
      </HfSection>
    );

  if (isMusic) {
    return (
      <>
        {trailerMarkup}
        {descriptionMarkup}
      </>
    );
  }

  return (
    <>
      {descriptionMarkup}
      {trailerMarkup}
    </>
  );
}

function ShowDetailsSkeleton() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9}>
          <Grid container spacing={2}>
            <Grid item className={classes.thumbnailWrapper}>
              <UnsafeThumbnail.Skeleton hideTitle />
            </Grid>
            <Grid item className={classes.titleWrapper}>
              <MaterialSkeleton
                variant="text"
                height={60}
                style={{ marginTop: -14 }}
              />
              <MaterialSkeleton
                variant="text"
                height={1.75 * 16}
                style={{ marginTop: -7 }}
              />
              <MaterialSkeleton
                variant="text"
                height={20}
                width={35}
                style={{ marginBottom: 6, marginTop: 6 }}
              />
              <MaterialSkeleton variant="text" height={30} width={300} />
              <HfSection>
                <Grid container spacing={1}>
                  {repeat(4, (key) => (
                    <Grid item>
                      <MaterialSkeleton
                        key={`ske-link-${key}`}
                        variant="circle"
                        height={60}
                        width={60}
                      />
                    </Grid>
                  ))}
                </Grid>
              </HfSection>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 16,
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <MaterialSkeleton variant="rect" height={600} />
        </Grid>
      </Grid>
    </Container>
  );
}

function AgeRating({ ageRating }: { ageRating: AgeRatingType }) {
  const guideMarkup = ageRating.guide && `・${ageRating.guide}`;

  return (
    <Typography variant="button">
      <u>Age rating</u>: {ageRating.rating}
      {guideMarkup}
    </Typography>
  );
}
