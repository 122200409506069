import { makeStyles, Typography } from "@material-ui/core";
import { ReactNode } from "react";
import { BorderLinearProgress } from "./BorderLinearProgress";

const useStyles = makeStyles(() => ({
  subsection: {
    marginTop: "1rem",

    "&:first-child": {
      marginTop: 0,
    },
  },
}));

export function DetailsSubSection({
  title,
  content,
  progress,
  raw,
}: {
  title?: string;
  content: ReactNode;
  progress?: boolean | null;
  raw?: boolean | null;
}) {
  const classes = useStyles();

  const contentMarkup = progress ? (
    <BorderLinearProgress
      variant="determinate"
      value={content as number}
      style={{ marginTop: 16 }}
    />
  ) : raw ? (
    content
  ) : (
    <Typography variant="caption">{content}</Typography>
  );

  return (
    <div className={classes.subsection}>
      {title && <Typography variant="subtitle1">{title}</Typography>}
      {contentMarkup}
    </div>
  );
}
