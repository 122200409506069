import { useTheme } from "@material-ui/core";
import { Platform } from "src/generated/graphql";
import { TagsList } from "src/sections/ViewShow/components/ShowDetails/components/TagsList";

interface Props {
  platform: Platform;
  limit?: number;
}

export function CountryTags({ platform, limit }: Props) {
  const theme = useTheme();

  const { countries } = platform;
  const items = countries
    ? countries.map((country) => ({
        value: country.name,
        colour: country.myCountry && theme.palette.primary.light,
      }))
    : [];

  if (platform.global) {
    items.push({
      value: "All around the world",
      colour: theme.palette.primary.light,
    });
  }

  return (
    <TagsList
      allowShowAll
      items={items}
      limit={limit}
      itemName={{ singular: "country", plural: "countries" }}
    />
  );
}
