import React, { useContext, useEffect } from "react";
import { ReactNode, useState } from "react";
import { useGetManyShowsBySlugQuery } from "src/generated/graphql";
import { noEmptyString, notEmpty } from "src/utils";

interface Props {
  children: ReactNode;
}

const STORAGE_KEY = "watchlist-local";
const SEPARATOR = ",";

export function WatchListProvider({ children }: Props) {
  const [watchList, setWatchList] = useState<string[]>(getWatchList());

  const addToWatchList = (slug: string) => {
    console.log("adding", slug);
    setWatchList((currentList: string[]) => [...currentList, slug]);
  };

  const removeFromWatchList = (slug: string) => {
    setWatchList((currentList: string[]) => {
      return currentList.filter((existingSlug) => existingSlug !== slug);
    });
  };

  useEffect(() => {
    console.log("new list", watchList);
    localStorage.setItem(STORAGE_KEY, watchList.join(SEPARATOR));
  }, [watchList]);

  return (
    <WatchListContext.Provider
      value={{ watchList, addToWatchList, removeFromWatchList }}
    >
      {children}
    </WatchListContext.Provider>
  );
}

export const WatchListContext = React.createContext({
  watchList: [] as string[],
  addToWatchList: (_: string) => {},
  removeFromWatchList: (_: string) => {},
});

const getWatchList = () =>
  localStorage
    .getItem(STORAGE_KEY)
    ?.split(SEPARATOR)
    .map((slug) => slug.trim())
    .filter(noEmptyString) || [];

export function useWatchListContext() {
  return useContext(WatchListContext);
}

export function useWatchList({ first }: { first: number }) {
  const { watchList: slugs } = useWatchListContext();
  const result = useGetManyShowsBySlugQuery({ variables: { slugs, first } });

  const watchList =
    result.data?.shows.edges?.map((edge) => edge?.node).filter(notEmpty) || [];

  return { ...result, watchList };
}
