import React, { ReactNode, useCallback, useRef } from "react";

import { FeaturedProp, Maybe, Platform } from "src/generated/graphql";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { sharedStyles } from "./sharedStyles";
import { notEmpty, useImageLoaded } from "src/utils";
import { Skeleton } from "./Skeleton";
import { Link } from "src/sections/components";

import clsx from "clsx";
import { PlatformIcon } from "src/components/global/PlatformIcon";
import { Button, useMediaQuery } from "@material-ui/core";

import "./Thumbnail.scss";
import { timeAgo } from "src/utils/date";

export declare namespace Thumbnail {
  export type PartialShow = {
    posterUrl: string;
    slug: string;
    friendlyStatus?: Maybe<string>;
    title: string;
    showType: string;
    year?: Maybe<number>;
    airingAt?: string;
    nextEpisode?: Maybe<number>;
    platforms?: Pick<Platform, "name" | "title">[];
  };

  export type ShowableProps = keyof Pick<
    Thumbnail.PartialShow,
    "friendlyStatus" | "year" | "airingAt" | "nextEpisode"
  >;
}

interface Props {
  show: Thumbnail.PartialShow;
  raw?: boolean | null;
  clickable?: boolean | null; // useful when hideTitle is true.
  hideTitle?: boolean | null;
  displayPlatforms?: boolean | null;
  showPropsAsBadge?: FeaturedProp[];
  smaller?: boolean | null;
  style?: React.CSSProperties;
  className?: string;

  action?: {
    content: string;
    onClick: () => void;
  };
}

interface TransformedFeaturedProp {
  prop: Thumbnail.ShowableProps;
  transform?: (value?: any) => any;
}

const mapShowPropsFor = (
  featuredProp: FeaturedProp
): TransformedFeaturedProp => {
  switch (featuredProp) {
    case FeaturedProp.AiringAt:
      return { prop: "airingAt", transform: timeAgo };
    case FeaturedProp.FriendlyStatus:
      return { prop: "friendlyStatus" };
    case FeaturedProp.Year:
      return { prop: "year" };
    case FeaturedProp.NextEpisode:
      return { prop: "nextEpisode", transform: (value) => `Episode ${value}` };
  }
};

export function Thumbnail({
  show,
  raw,
  hideTitle,
  clickable,
  displayPlatforms,
  showPropsAsBadge = [],
  action,
  style,
  className,
}: Props) {
  const classes = useStyles();
  const imageUrl = show.posterUrl;
  const { loaded: imageLoaded, error: imageError } = useImageLoaded(imageUrl);
  const imageRef = useRef<HTMLImageElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const platforms = (displayPlatforms && show.platforms) || [];
  const canHover = !Boolean(displayPlatforms);

  const onImageLoad = useCallback(() => {
    if (imageRef.current) {
      imageRef.current.classList.add(classes.loaded);
    }
    if (overlayRef.current) {
      overlayRef.current.classList.add(classes.overlayLoaded);
    }
  }, [classes.loaded, classes.overlayLoaded]);

  const imageMarkup =
    imageLoaded && !imageError ? (
      <img
        alt={show.title}
        ref={imageRef}
        onLoad={onImageLoad}
        className={clsx(
          (hideTitle && !clickable) || !canHover
            ? classes.imageNoHover
            : classes.image,
          className
        )}
        src={imageUrl}
        style={style}
      />
    ) : (
      <Skeleton error={imageError} {...show} />
    );

  if (raw) {
    return imageMarkup;
  }

  return (
    <div
      className={clsx({ [classes.thumbnail]: !hideTitle }, classes.root)}
      style={{ position: "relative" }}
      key={show.slug}
    >
      <Wrapper hideTitle={hideTitle} clickable={clickable} show={show}>
        <div style={{ position: "relative" }}>
          <div className={classes.holder}>
            {displayPlatforms && platforms.length > 0 && (
              <div ref={overlayRef} className={classes.overlay} />
            )}
          </div>

          <div className={clsx(classes.thumbnailWrapper, classes.container)}>
            <div className={classes.showShowPropContainer}>
              {showPropsAsBadge
                .map((showProp) => {
                  const transformedProp = mapShowPropsFor(showProp);
                  const { transform, prop } = transformedProp;
                  const propValue = transform
                    ? transform(show[prop])
                    : show[prop];
                  return propValue ? (
                    <span
                      key={`show-prop-${propValue}`}
                      className={classes.showShowProp}
                    >
                      {propValue}
                    </span>
                  ) : null;
                })
                .filter(notEmpty)}
            </div>
            <span className={classes.platformIconsContainer}>
              {platforms.map((platform) => (
                <PlatformIcon
                  key={`platform-${platform.name}`}
                  platform={platform}
                  height={25}
                  width={25}
                  style={{ verticalAlign: "middle" }}
                />
              ))}
            </span>
            {imageMarkup}
            {action && <Button {...action}>{action.content}</Button>}
          </div>
        </div>

        {!hideTitle && (
          <span className={clsx("test", classes.title)}>{show.title}</span>
        )}
      </Wrapper>
    </div>
  );
}

const Wrapper = ({
  hideTitle,
  clickable,
  show,
  children,
}: {
  hideTitle?: boolean | null;
  clickable?: boolean | null;
  show: Thumbnail.PartialShow;
  children: ReactNode;
}) => {
  if (hideTitle && !clickable) {
    return <>{children}</>;
  }

  return (
    <Link to={`/shows/${show.slug}`} color="textSecondary">
      {children}
    </Link>
  );
};

export function useThumbnailDimensions() {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  if (isXl) {
    return { width: 250, height: 358 };
  }

  return { width: 185, height: 265 };
}

Thumbnail.Skeleton = Skeleton;

export default React.memo(Thumbnail, (prevProps, nextProps) => {
  // Don't re-render if it's the same slug :P
  return prevProps.show.slug === nextProps.show.slug;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: 185,
    height: 265,
    marginBottom: theme.spacing(2),

    // [theme.breakpoints.up("xl")]: {
    //   width: 250,
    //   height: 358,
    // },
  },
  holder: {
    position: "relative",
    overflow: "hidden",
    padding: "0 0 56.5%",
    height: 265,

    // [theme.breakpoints.up("xl")]: {
    //   height: 358,
    // },
  },
  overlay: {
    zIndex: 1,
    width: "100%",
    height: "100%",
    position: "absolute",

    "&::after": {
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      content: '""',
      background:
        "linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.7) 100%)",

      opacity: 0,
      transition: "opacity .7s ease-out",
    },
  },
  overlayLoaded: {
    "&::after": {
      opacity: 1,
    },
  },
  thumbnail: {
    cursor: "pointer",
  },
  thumbnailWrapper: {
    position: "absolute",
    top: 0,
  },
  extraInfo: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 0,
    transition: "all .7s ease-in-out;",

    "&:hover": {
      zIndex: 2,
    },
  },
  imageContainer: {
    height: 265,
    width: "100%",

    // [theme.breakpoints.up("xl")]: {
    //   height: 358,
    // },
  },
  image: {
    height: 265,
    width: 185,
    objectFit: "cover",
    transition: "all .7s ease-in-out;",

    "&:hover": {
      transform: "scale(1.03)",
      // filter: "blur(1px)",
      opacity: 0.6,
    },
    //"@keyframes fadeIn": {
    //  ...animate(),
    //},

    // [theme.breakpoints.up("xl")]: {
    //   width: 250,
    //   height: 358,
    // },
  },
  imageNoHover: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    opacity: 0,
    transition: "opacity .7s ease-out",
  },
  loaded: {
    opacity: 1,
  },
  showShowPropContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 20,
  },
  showShowProp: {
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    "&:nth-child(odd)": {
      background: theme.palette.info.dark,
    },
    "&:nth-child(even)": {
      background: theme.palette.info.light,
    },
  },
  platformIconsContainer: {
    position: "absolute",
    bottom: 0,
    left: 10,
    zIndex: 20,

    "& > *": {
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(0.75),
    },
  },
  ...sharedStyles(theme),
}));
