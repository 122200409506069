import { Alert, AlertTitle } from "@material-ui/lab";
import { Layout, useGetCategoryBySlugQuery } from "src/generated/graphql";
import { notEmpty } from "src/utils";
import { ShowsList } from "../ShowsList";

interface Props {
  slug: string;
  layout: Layout;
  title?: string;
  allowViewMore?: boolean;
  limitBasedOnWindowSize?: boolean;
  allowCache?: boolean;
  cacheForUser?: boolean;
}

function useShowsSizeByLayout(layout: Layout) {
  switch (layout) {
    case Layout.Simple:
      return 6;
    case Layout.Expanded:
      return 15;
  }
}

export function CategoryShowsList({
  slug,
  layout,
  title,
  allowViewMore,
  limitBasedOnWindowSize,
  allowCache,
  cacheForUser,
}: Props) {
  const first = useShowsSizeByLayout(layout);
  const { data, loading, error } = useGetCategoryBySlugQuery({
    variables: { slug, first },
    context: {
      headers: {
        "X-Allow-Cache": allowCache ? "true" : "false",
        "X-Should-Auth": cacheForUser ? "true" : "false",
      },
    },
  });

  const category = data?.homePageCategory;

  if (loading || error || !category) {
    return (
      <ShowsList.Skeleton
        key={`${slug}-loading`}
        title={title}
        error={error || (!loading && !category)}
        limitBasedOnWindowSize={limitBasedOnWindowSize}
        xs={6}
        sm={4}
        md={3}
        lg={2}
      />
    );
  }

  const shows =
    category.shows.edges?.map((edge) => edge?.node).filter(notEmpty) || [];

  const emptyState = category.key === "your-watch-list" && (
    <Alert severity="info">
      <AlertTitle>Add shows to your watch list!</AlertTitle>
      To get started, you can browse the site, look up any shows you're
      interested in. From there, you will be able to{" "}
      <strong>add that show to your watch list</strong>.
    </Alert>
  );

  return (
    <ShowsList
      allowViewMore={allowViewMore}
      shows={shows}
      totalCount={category.shows.pageInfo.totalCount}
      title={title || category.title}
      showPropsAsBadge={category.featuredProps}
      key={slug}
      limitBasedOnWindowSize={limitBasedOnWindowSize}
      viewMoreLink={`/categories/${slug}`}
      emptyState={emptyState}
      xs={6}
      sm={4}
      md={3}
      lg={2}
    />
  );
}
