import { makeStyles, TextField, IconButton } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDebounceedValue } from "src/utils";

import ShowAsThumbnailsIcon from "@material-ui/icons/Apps";
import ShowAsListIcon from "@material-ui/icons/FormatListBulleted";

interface Props {
  currentDisplayType: DisplayTypeOption;
  onDisplayTypeChange: (displayType: DisplayTypeOption) => void;
  onSearchChange?: (query: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export type DisplayTypeOption = "bulleted" | "thumbnail";

export function UtilityTopComponent({
  currentDisplayType,
  onDisplayTypeChange,
  onSearchChange,
}: Props) {
  const classes = useStyles();

  const [input, setInput] = useState("");
  const query = useDebounceedValue(input, 500).trim();

  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(query);
    }
  }, [query, onSearchChange]);

  return (
    <div className={classes.root}>
      <div>
        {onSearchChange && (
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            onChange={(event) => setInput(event.target.value)}
          />
        )}
      </div>
      <div>
        <IconButton
          onClick={() => onDisplayTypeChange("thumbnail")}
          color={currentDisplayType === "thumbnail" ? "primary" : undefined}
        >
          <ShowAsThumbnailsIcon />
        </IconButton>
        <IconButton
          onClick={() => onDisplayTypeChange("bulleted")}
          color={currentDisplayType === "bulleted" ? "primary" : undefined}
        >
          <ShowAsListIcon />
        </IconButton>
      </div>
    </div>
  );
}
