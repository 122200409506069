import { Link, Typography } from "@material-ui/core";
import React from "react";

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <em>YourAnime.moe</em>
      <br />
      <Link color="inherit" href="https://www.akinyele.ca/projects">
        My other projects.
      </Link>{" "}
      2016 - {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
