import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(6),

    width: '100%',
    position: 'absolute',
    bottom: 0,
    height: '12rem',
  },
  footerBackground: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.7,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '12rem',
  },
  name: {
    fontWeight: "bold",
  },
}));

export default useStyles;
