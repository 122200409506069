import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useRef } from "react";
import { ReactNode, useState } from "react";
import { useLocation } from "react-router";
import { openInNewTab } from "src/utils";
import { getAPIHost } from "../GraphQLProvider";

interface Props {
  children: ReactNode;
}

interface SessionStateManagement {
  loading: boolean;
  valid: boolean;
  value: string | null;
  user?: User | null;
  goToUserAccount?: () => void;
  onLogin: () => void;
  onLogout: () => void;
}

export interface User {
  active: boolean;
  blocked: boolean;
  color_hex: string;
  email: string;
  first_name: string;
  id: number;
  image: string;
  last_name: "OAuth";
  name: string;
  username: string;
  uuid: string;
  external_oauth_grants: UserGrants[];
}

export interface UserGrants {
  grant_name: string;
  grant_user_id: string;
}

interface Session {
  valid: boolean;
  validUntil: Date | null;
  value: string | null;
  user?: User | null;
}

export function SessionProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);
  const [tokenValidationErrored, setTokenValidationErrored] = useState(false);
  const [session, setSession] = useState<Session>({
    valid: false,
    validUntil: null,
    value: null,
  });
  const attemptedRefreshToken = useRef(false);
  const client = useApolloClient();
  const location = useLocation();

  const checkSession = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    let headers = new Headers({
      Authorization: "Bearer " + sessionStorage["auth.access_token"],
    });

    fetch(getAPIHost("/me"), {
      method: "GET",
      headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          const user = res.response as User;
          setSession((session) => ({
            ...session,
            valid: true,
            value: sessionStorage["auth.access_token"],
            user,
          }));
          setLoading(false);
        } else {
          setTokenValidationErrored(true);
        }
      })
      .catch((e) => {
        console.error(e);
        setTokenValidationErrored(true);
      });
  };

  // need to fix this
  useEffect(() => {
    checkSession();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tokenValidationErrored && !attemptedRefreshToken.current) {
      refreshUserToken()
        .then((res) => {
          let headers = new Headers({
            Authorization: "Bearer " + sessionStorage["auth.access_token"],
          });

          if (res) {
            fetch(getAPIHost("/me"), {
              method: "GET",
              headers,
            })
              .then((res) => res.json())
              .then((res) => {
                if (res.success) {
                  const user = res.response as User;
                  setSession((session) => ({
                    ...session,
                    valid: true,
                    value: sessionStorage["auth.access_token"],
                    user,
                  }));
                  setLoading(false);
                }
              });
          }
        })
        .finally(() => {
          attemptedRefreshToken.current = true;
          setLoading(false);
        });
    }
  }, [tokenValidationErrored]);

  const onLogin = () => {
    localStorage.setItem("next.path", location.pathname);
    window.location.replace("/authorize");
  };

  const goToUserAccount = () => {
    openInNewTab("/my-account");
  };

  const onLogout = () => {
    setLoading(true);
    const headers = new Headers({
      Authorization: "Bearer " + sessionStorage["auth.access_token"],
    });

    fetch(getAPIHost("/logout"), {
      method: "DELETE",
      headers,
    })
      .then((res) => res.json())
      .then((_res) => {
        setSession({ valid: false, user: null, value: null, validUntil: null });
        sessionStorage.setItem("auth.access_token", "");
        sessionStorage.setItem("auth.refresh_token", "");
        client.resetStore();
      })
      .finally(() => setLoading(false));
  };

  return (
    <SessionContext.Provider
      value={{ ...session, loading, goToUserAccount, onLogin, onLogout }}
    >
      {children}
    </SessionContext.Provider>
  );
}

const refreshUserToken = async () => {
  const refreshToken = sessionStorage["auth.refresh_token"] || "";

  const headers = new Headers({
    "X-Refresh-Token": refreshToken,
  });

  const response = await fetch(getAPIHost("/refresh"), {
    method: "POST",
    headers,
  });

  const data = await response.json();
  if (data.success) {
    Object.keys(data.response).forEach((key) => {
      sessionStorage.setItem(`auth.${key}`, data.response[key]);
    });
    return data;
  }

  return null;
};

export const SessionContext = React.createContext<SessionStateManagement>({
  loading: false,
  valid: false,
  value: null,
  onLogin: () => null,
  onLogout: () => null,
});

export function useIsAuthenticated() {
  const { valid, user } = useContext(SessionContext);

  return Boolean(valid && user);
}

export function useCurrentUser() {
  const { user } = useContext(SessionContext);

  return user == null ? null : user;
}

export function RedirectIfUnauthenticated({
  to = "/authorize",
  children,
}: {
  to?: string;
  children: ReactNode;
}) {
  const { loading, valid, user } = useContext(SessionContext);
  const authenticated = Boolean(valid && user);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!authenticated) {
      window.location.replace(to);
    }
  }, [authenticated, loading, to]);

  if (authenticated) {
    return <>{children}</>;
  }

  return null;
}

export function RequireAuthenticated({ children }: { children: ReactNode }) {
  const authenticated = useIsAuthenticated();

  if (authenticated) {
    return <>{children}</>;
  }

  return null;
}

export function useAccessToken() {
  const { value } = useContext(SessionContext);

  return value || sessionStorage.getItem("auth.access_token");
}
