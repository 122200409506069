import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  welcome: {
    margin: theme.spacing(3, 0),

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(7, 0, 7),
    borderRadius: "20px",
    boxShadow: `0 0 30px ${theme.palette.type === "dark" ? "#111" : "#eee"}`,

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 0, 5),
    },
  },
  heroButtons: {
    marginTop: theme.spacing(4),
    display: "none",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  hidden: {
    display: "none",
  },
}));

export function Hero() {
  const classes = useStyles();
  const history = useHistory();

  return (
    (
      <div className={classes.welcome}>
        <Container maxWidth="md" className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              The next go-to anime streaming information website.
            </Typography>
          </Container>
          <Container maxWidth="sm">
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              paragraph
            >
              Find out when your favourite anime airs and where you can watch
              it. Organize your watch list per streaming platform.
            </Typography>
          </Container>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/platforms")}
                >
                  Browse streaming platforms
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    ) || null
  );
}
