import { useGetTagsForSearchQuery } from "src/generated/graphql";
import { notEmpty } from "src/utils";

export function useTags() {
  const result = useGetTagsForSearchQuery();

  return {
    ...result,
    tags: result.data?.showTags.edges?.map((edge) => edge && edge.node).filter(notEmpty) || []
  };
}
