import GraphiQL, { FetcherParams } from "graphiql";
import "graphiql/graphiql.min.css";
import { useDocumentTitle } from "src/components/global";
import { useAccessToken, useGraphQLUrl } from "src/config";

const QUERY_KEY = "playground.query";
const VARIABLES_KEY = "playground.variables";

export function Playground() {
  useDocumentTitle({ title: "GraphQL playground" });
  const graphQLUri = useGraphQLUrl();
  const accessToken = useAccessToken();

  const savedQuery = localStorage.getItem(QUERY_KEY);
  const savedVariables = localStorage.getItem(VARIABLES_KEY);

  const onEditQuery = (query?: string) => {
    if (query) {
      localStorage.setItem(QUERY_KEY, query);
    }
  };

  const onEditVariables = (value: string) => {
    localStorage.setItem(VARIABLES_KEY, value);
  };

  const defaultContents = [
    "# Welcome to YourAnime.moe's playground!",
    `# This playground fetches its data from "${graphQLUri}"`,
    "# Here are below a sample query.",
    "# Please check the documentation on the right! :)",
    "",
    "query GetPlatform($name: String!) {",
    "  platform(name: $name) {",
    "    title",
    "    name",
    "    airingNow(first: 5) {",
    "      pageInfo {",
    "        totalCount",
    "      }",
    "      edges {",
    "        node {",
    "          title",
    "          startsOn",
    "        }",
    "      }",
    "    }",
    "    comingSoon(first: 5)  {",
    "      pageInfo {",
    "        totalCount",
    "      }",
    "      edges {",
    "        node {",
    "          title",
    "          startsOn",
    "        }",
    "      }",
    "    }",
    "    otherShows(first: 5)  {",
    "      pageInfo {",
    "        totalCount",
    "      }",
    "      edges {",
    "        node {",
    "          title",
    "          startsOn",
    "          endedOn",
    "        }",
    "      }",
    "    }",
    "  }",
    "}",
    "",
  ];

  const defaultVariables = JSON.stringify({
    name: "crunchyroll",
  });

  return (
    <GraphiQL
      docExplorerOpen
      query={savedQuery || defaultContents.join("\n")}
      variables={savedVariables || defaultVariables}
      onEditQuery={onEditQuery}
      onEditVariables={onEditVariables}
      fetcher={fetcher(graphQLUri, accessToken)}
    />
  );
}

export function fetcher(graphQLUri: string, accessToken: string | null) {
  return async (params: FetcherParams) => {
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    if (accessToken) {
      headers.append("Authorization", `Bearer ${accessToken}`);
    }

    const data = await fetch(graphQLUri, {
      method: "POST",
      body: JSON.stringify(params),
      headers,
    });

    return data.json().catch(() => data.text());
  };
}
