import { Show } from "src/generated/graphql";
import { openInNewTab } from "src/utils";
import { TagsList } from "./TagsList";

interface Props {
  show: Show;
  showPlatforms?: boolean;
  limit?: number;
  allowShowAll?: boolean;
}

export function OtherLinksTagsList({
  show: { otherLinks },
  limit,
  allowShowAll,
}: Props) {
  const items = otherLinks.map((otherLink) => ({
    value: otherLink.urlType,
    colour: otherLink.color,
    onClick: () => openInNewTab(otherLink.value),
  }));

  return (
    <TagsList
      allowShowAll={allowShowAll}
      items={items}
      noTagsText="- No links -"
      limit={limit}
    />
  );
}
