import { Platform } from 'src/generated/graphql';
import adultswim from './adultswim-icon.png';
import animelab from './animelab-icon.jpg';
import crunchyroll from './crunchyroll-icon2.png';
import funimation from './funimation-icon.png';
import hidive from './hidive-icon.png';
import hulu from './hulu-icon.png';
import netflix from './netflix-icon.jpg';
import primevideo from './primevideo-icon.jpg';
import tubi from './tubi-icon.png';
import vimeo from './vimeo-icon.png';
import vrv from './vrv-icon.png';
import youtube from './youtube-icon.png';

export const platforms = {
  adultswim: adultswim,
  animelab: animelab,
  crunchyroll: crunchyroll,
  funimation: funimation,
  hidive: hidive,
  hulu: hulu,
  netflix: netflix,
  prime: primevideo,
  tubi: tubi,
  vimeo: vimeo,
  vrv: vrv,
  youtube: youtube,
};

export const platformIcon = (platform: Pick<Platform, 'name'>) => platforms[platform.name as PlatformIcons];

export type PlatformIcons = keyof(typeof platforms);
