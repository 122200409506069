import {
  Button,
  Container,
  Grid,
  GridSize,
  makeStyles,
} from "@material-ui/core";
import { ApolloError } from "apollo-client";
import clsx from "clsx";
import { ReactNode } from "react";
import { useHistory } from "react-router";
import { HfSection } from "src/components/global/HfSection";
import {
  Skeleton as ThumbnailSkeleton,
  Thumbnail,
  UnsafeThumbnail,
} from "src/components/shows";
import { useLoading } from "src/config";
import { FeaturedProp } from "src/generated/graphql";
import { repeat, useCurrentBreakpoint } from "src/utils";

const itemsToRenderCountMap = {
  xs: 4,
  sm: 3,
  md: 4,
  lg: 6,
  xl: 6,
};

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

type GridProps = {
  [key in Breakpoint]?: GridSize | boolean | undefined;
};

interface BasicProps {
  title?: ReactNode;
  hideTitle?: boolean;
  error?: ApolloError | boolean | string;
  limit?: number;
  limitBasedOnWindowSize?: boolean;
}

// For ShowsList
interface LoadedProps {
  shows: UnsafeThumbnail.PartialShow[];
  totalCount?: number;
  showPropsAsBadge?: FeaturedProp[];
  allowViewMore?: boolean;
  viewMoreLink?: string;
  warning?: ReactNode;
  emptyState?: ReactNode;
}

// For ShowsList.Sketon
interface LoadingProps {
  partial?: boolean;
  staticAnimation?: boolean;
}

type ShowsListProps = BasicProps & LoadedProps & GridProps;
type SkeletonProps = BasicProps & LoadingProps & GridProps;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },

  thumbnailWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
}));

const Skeleton = (props: SkeletonProps) => {
  const {
    limit,
    title,
    partial,
    staticAnimation,
    limitBasedOnWindowSize,
    error,
  } = props;

  const gridProps = props as GridProps;

  const classes = useStyles();
  useLoading(true);

  const currentBreakpoint = useCurrentBreakpoint() as Breakpoint;
  const repeatCount = limitBasedOnWindowSize
    ? itemsToRenderCountMap[currentBreakpoint]
    : limit
    ? limit
    : 27;

  return (
    <Container maxWidth="xl" className={clsx(!partial && classes.root)}>
      <HfSection
        title={title}
        hideTitle={partial}
        loading={!error && !staticAnimation}
      >
        <Grid container spacing={3}>
          {repeat(repeatCount, (itemKey) => (
            <Grid
              key={itemKey}
              item
              {...gridProps}
              className={classes.thumbnailWrapper}
            >
              <ThumbnailSkeleton
                staticAnimation={staticAnimation}
                error={Boolean(error)}
              />
            </Grid>
          ))}
        </Grid>
      </HfSection>
    </Container>
  );
};

export function RenderOnlyOn({
  breakpoints,
  children,
}: {
  breakpoints: Breakpoint[];
  children: ReactNode;
}) {
  const currentBreakpoint = useCurrentBreakpoint() as Breakpoint;

  if (breakpoints.includes(currentBreakpoint)) {
    return { children };
  }

  return null;
}

export function ShowsList(props: ShowsListProps) {
  const {
    shows,
    totalCount,
    showPropsAsBadge,
    title,
    hideTitle,
    warning,
    emptyState,
    allowViewMore,
    limitBasedOnWindowSize,
    viewMoreLink,
  } = props;

  const gridProps = props as GridProps;

  const classes = useStyles();
  const history = useHistory();
  const currentBreakpoint = useCurrentBreakpoint() as Breakpoint;
  useLoading(false);

  const shouldShowViewMore =
    allowViewMore && Boolean(viewMoreLink && limitBasedOnWindowSize);
  const viewMoreLinkMarkup = shouldShowViewMore && viewMoreLink && (
    <Button onClick={() => history.push(viewMoreLink)}>
      View all{totalCount && ` (${totalCount})`}
    </Button>
  );

  if (shows.length === 0) {
    return emptyState ? (
      <Container maxWidth="xl" className={classes.root}>
        {emptyState}
      </Container>
    ) : null;
  }

  const showsToRender = limitBasedOnWindowSize
    ? shows.slice(0, itemsToRenderCountMap[currentBreakpoint])
    : shows;

  return (
    <Container maxWidth="xl" className={classes.root}>
      <HfSection
        title={title}
        hideTitle={hideTitle}
        primaryAction={viewMoreLinkMarkup}
      >
        {warning}
        <Grid container spacing={3}>
          {showsToRender.map((show) => (
            <Grid
              key={`show-${show.slug}`}
              item
              {...gridProps}
              className={classes.thumbnailWrapper}
            >
              <Thumbnail
                displayPlatforms
                show={show}
                showPropsAsBadge={showPropsAsBadge}
              />
            </Grid>
          ))}
        </Grid>
      </HfSection>
    </Container>
  );
}

ShowsList.Skeleton = Skeleton;
