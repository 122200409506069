import React from "react";
import { useParams } from "react-router-dom";
import { useDocumentTitle } from "src/components/global";
import { useLoading } from "src/config";
import { Platform, useGetPlatformByNameQuery } from "src/generated/graphql";
import { useBackgroundImage } from "../hooks/useBackgroundImage";
import { PlatformDetails } from "./components";

export function PlatformDetailsContainer() {
  const { name } = useParams<{ name: string }>();
  const { loading, platform } = usePlatform(name);
  useLoading(loading);
  useBackgroundImage(platform?.image || platform?.icon || "", 0.05);
  useDocumentTitle({ title: "Loading..." });

  if (platform) {
    return <PlatformDetails platform={platform} />;
  }

  return null;
}

function usePlatform(name: string) {
  const result = useGetPlatformByNameQuery({ variables: { name } });
  const { data } = result;

  if (data && data.platform) {
    return { ...result, platform: data.platform as Platform };
  }

  return { ...result, platform: null };
}
