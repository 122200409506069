import { Theme, ThemeProvider } from "@material-ui/core";
import React, { ReactNode, useEffect, useState } from "react";
import { darkTheme, lightTheme } from "src/App/themes";
import { BackgroundProvider } from "./BackgroundProvider";

interface Props {
  children: ReactNode;
}

interface ThemeStateManagement {
  isDarkTheme: boolean;
  onToggle: () => void;
}

const STORAGE_KEY = "dark-on";

export function YourAnimeThemeProvider({ children }: Props) {
  const chooseTheme = (darkThemeRequested: boolean) =>
    darkThemeRequested ? darkTheme() : lightTheme();

  const [darkThemeRequested, setDarkThemeRequested] = useState(
    checkDarkTheme()
  );
  const [theme, setTheme] = useState<Theme>(chooseTheme(darkThemeRequested));

  useEffect(() => {
    setTheme(chooseTheme(darkThemeRequested));
    localStorage.setItem(STORAGE_KEY, darkThemeRequested ? "on" : "off");
  }, [darkThemeRequested]);

  const onToggle = () => {
    setDarkThemeRequested(!darkThemeRequested);
  };

  return (
    <YourAnimeThemeContext.Provider
      value={{ isDarkTheme: darkThemeRequested, onToggle }}
    >
      <ThemeProvider theme={theme}>
        <BackgroundProvider>{children}</BackgroundProvider>
      </ThemeProvider>
    </YourAnimeThemeContext.Provider>
  );
}

export const YourAnimeThemeContext = React.createContext<ThemeStateManagement>({
  isDarkTheme: true,
  onToggle: () => {},
});

const checkDarkTheme = () =>
  !localStorage.getItem(STORAGE_KEY) ||
  localStorage.getItem(STORAGE_KEY) === "on";
