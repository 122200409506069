import { useCallback, useState } from "react";
import {
  useAddToWatchListMutation,
  useRemoveFromWatchListMutation,
} from "src/generated/graphql";

export function useWatchListManagement(slug: string) {
  const [loading, setLoading] = useState(false);

  const [
    addToWatchListPromise,
    { loading: addLoading },
  ] = useAddToWatchListMutation();
  const [
    removeFromWatchListPromise,
    { loading: removeLoading },
  ] = useRemoveFromWatchListMutation({
    variables: { slug },
  });

  const addToWatchList = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    addToWatchListPromise({
      variables: { slug },
    }).finally(() => setLoading(false));
  }, [loading, addToWatchListPromise, slug]);

  const removeFromWatchList = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    removeFromWatchListPromise({
      variables: { slug },
    }).finally(() => setLoading(false));
  }, [loading, removeFromWatchListPromise, slug]);

  return {
    loading: addLoading || removeLoading,
    addToWatchList,
    removeFromWatchList,
  };
}
