import { useParams } from "react-router-dom";
import { useLoading } from "src/config";
import { useQueryParam } from "src/utils";
import { useBackgroundImage } from "../hooks/useBackgroundImage";
import { useShow } from "../hooks/useShow";
import { NotFound } from "../NotFound";
import { ShowDetails } from "./components";

export function ShowDetailsContainer() {
  const { slug } = useParams<{
    slug: string;
  }>();
  const {
    loading: gqlLoading,
    show,
    nextAiringEpisode,
    error,
    refetching,
  } = useShow(slug);

  const isLoading = useQueryParam("isLoading") === "true";
  const loading = gqlLoading || isLoading;

  useLoading(loading);
  useBackgroundImage(show?.bannerUrl || "");

  if (show || loading) {
    return (
      <ShowDetails
        loading={loading}
        refetching={refetching}
        show={show}
        nextAiringEpisode={nextAiringEpisode}
      />
    );
  }

  if (error) {
    return <p>Uh-oh, something didn't go right on our side :/</p>;
  }

  return <NotFound />;
}
