import { NetworkStatus } from "@apollo/client";
import {
  AiringSchedule,
  Show,
  useGetShowBySlugQuery,
} from "src/generated/graphql";

export function useShow(slug: string) {
  const result = useGetShowBySlugQuery({
    variables: { slug },
    fetchPolicy: "network-only",
  });

  const { data, networkStatus } = result;
  const refetching = networkStatus === NetworkStatus.refetch;

  if (data && data.show) {
    const nextAiringEpisode = data.nextAiringEpisode as AiringSchedule;
    const show = data.show as Show;
    return { ...result, refetching, show, nextAiringEpisode };
  }

  return { ...result, refetching, show: null, nextAiringEpisode: null };
}
