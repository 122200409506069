import { NetworkStatus } from "@apollo/client";
import { CircularProgress, Container, makeStyles } from "@material-ui/core";
import {
  RedirectIfUnauthenticated,
  useLoading,
  useWatchList,
} from "src/config";
import { FeaturedProp } from "src/generated/graphql";
import { useScrollListeners } from "src/utils";
import { ShowsList } from "./HomePage/components/ShowsList";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

function WatchListPage() {
  const classes = useStyles();
  const {
    watchList: shows,
    data,
    loading,
    networkStatus,
    fetchMore,
  } = useWatchList({ first: 18 });

  useLoading(loading);

  useScrollListeners(
    null,
    async () => {
      if (data?.shows.pageInfo.hasNextPage) {
        fetchMore({
          variables: {
            after: data.shows.pageInfo.endCursor,
          },
        });
      }
    },
    { offset: 200 }
  );

  const loadingMarkup = loading && (
    <>
      <ShowsList.Skeleton limit={18} xs={6} sm={4} md={3} lg={2} />
    </>
  );

  const fetchingingMoreMarkup = networkStatus === NetworkStatus.fetchMore && (
    <>
      <ShowsList.Skeleton partial limit={6} xs={6} sm={4} md={3} lg={2} />
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    </>
  );

  return (
    <Container key="watch-list">
      {loadingMarkup}
      <ShowsList
        title="Your watch list"
        showPropsAsBadge={[FeaturedProp.Year, FeaturedProp.FriendlyStatus]}
        shows={shows}
        xs={6}
        sm={4}
        md={3}
        lg={2}
      />
      {fetchingingMoreMarkup}
    </Container>
  );
}

export function ViewWatchList() {
  return (
    <RedirectIfUnauthenticated to="/">
      <WatchListPage />
    </RedirectIfUnauthenticated>
  );
}
