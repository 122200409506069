import React, { ReactNode, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useImageLoaded } from "src/utils";

interface Props {
  children: ReactNode;
}

export function BackgroundProvider({ children }: Props) {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [preferredOpactity, setPreferredOpactify] = useState(0);
  const onImageAvailable = (img: string, opacity?: number) => {
    setBackgroundImage(img);
    setPreferredOpactify(opacity || 0.15);
  };

  return (
    <>
      <BackgroundImageContext.Provider
        value={{ backgroundImage, onImageAvailable }}
      >
        <WithBackground
          url={backgroundImage}
          preferredOpacity={preferredOpactity}
        />
        {children}
      </BackgroundImageContext.Provider>
    </>
  );
}

export const BackgroundImageContext = React.createContext({
  backgroundImage: "",
  onImageAvailable: (_url: string, _opacity?: number) => {},
});

function WithBackground({
  url,
  preferredOpacity,
  children,
}: {
  url: string;
  preferredOpacity: number;
  children?: ReactNode;
}) {
  const [opacity, setOpacity] = useState(0);

  const { loaded, error } = useImageLoaded(url);

  useEffect(() => {
    if (loaded && url !== "") {
      setOpacity(preferredOpacity);
    } else if (url === "" || error) {
      setOpacity(0);
    }
  }, [loaded, error, url, preferredOpacity]);

  const useStyles = makeStyles((theme) => ({
    background: {
      width: "100%",
      height: "100%",

      opacity,
      background: `url(${url}) no-repeat center center fixed`,
      // backgroundAttachment: "fixed",
      backgroundSize: "cover",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "fixed",
      zIndex: -1,
      content: '""',
      transition: theme.transitions.create("opacity", { duration: "1.5s" }),
    },
  }));

  const classes = useStyles();

  return url ? (
    <div className={classes.background}>{children}</div>
  ) : (
    <>{children}</>
  );
}
