import {
  Avatar,
  Container,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { TabContext, TabPanel } from "@material-ui/lab";
import clsx from "clsx";
import { useRef, useState } from "react";
import { useDocumentTitle } from "src/components/global";
import { HfSection } from "src/components/global/HfSection";
import { useLoading } from "src/config";
import {
  Platform,
  Show,
  ShowConnection,
  useGetPlatformAiringNowQuery,
  useGetPlatformComingSoonQuery,
  useGetPlatformOtherShowsQuery,
} from "src/generated/graphql";
import { platformIcon } from "src/icons";
import { DisplayTypeOption, Link, ShowsList } from "src/sections/components";
import { useScrollListeners } from "src/utils";
import { CountryTags } from "./CountryTags";

interface Props {
  platform: Platform;
}

interface SectionProps {
  platform: Platform;
  displayType: DisplayTypeOption;
  onDisplayTypeChange: (displayType: DisplayTypeOption) => void;
}

enum TabEnum {
  AiringNow = "airing",
  ComingSoon = "coming",
  Discover = "discover",
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(6, 0),

    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(3, 3),
    },
  },
  topSectionContainer: {
    marginBottom: theme.spacing(3),
  },
  avatarTitleContainer: {
    maxWidth: 300,
    margin: "0 auto",
  },
  avatar: {
    width: 250,
    height: 250,

    [theme.breakpoints.down("lg")]: {
      width: 200,
      height: 200,
    },
    [theme.breakpoints.down("md")]: {
      width: 150,
      height: 150,
    },
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 100,
    },
  },
  title: {
    textAlign: "center",
    marginTop: theme.spacing(),
  },
  quickSearch: {
    width: 300,
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tabsContainer: {
    width: "100%",
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2, "auto"),
  },
  currentTab: {
    color: theme.palette.primary.light,
  },
}));

export function PlatformDetails({ platform }: Props) {
  const classes = useStyles();
  const scrollArea = useRef<HTMLDivElement>(null);

  useScrollListeners(document.body, () => {
    console.log("BOTTOMMM!!!");
  });

  let tab = localStorage.getItem("tab") || "airing";
  if (tab === "airing" && !platform.anyAiringNow) {
    tab = "discover";
  }
  const [currentTab, setCurrentTab] = useState<TabEnum>(tab as TabEnum);

  const displayType = localStorage.getItem("display-type") || "thumbnail";
  const [currentDisplayType, setDisplayType] = useState<DisplayTypeOption>(
    displayType as DisplayTypeOption
  );
  const onDisplayTypeChange = (displayType: DisplayTypeOption) => {
    localStorage.setItem("display-type", displayType);
    setDisplayType(displayType);
  };

  const countriesMarkup =
    (!platform.blocked && <CountryTags platform={platform} limit={1} />) ||
    null;

  return (
    <Container ref={scrollArea} maxWidth="xl" className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} className={classes.topSectionContainer}>
            <Grid item xs={12} md={3} lg={3} xl={2}>
              <Grid container className={classes.avatarTitleContainer}>
                <Grid item xs={12}>
                  <Avatar
                    src={platformIcon(platform)}
                    className={classes.avatar}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={9} lg={9} xl={10}>
              <Typography variant="h4">{platform.title}</Typography>
              <HfSection>
                <Grid container spacing={1}>
                  <Grid item>
                    <OpenInNewIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">Official site</Typography>
                  </Grid>
                </Grid>
                <Link external to={platform?.url || "/"}>
                  {platform?.url}
                </Link>
              </HfSection>
              {platform.blocked ? (
                <Typography variant="subtitle1">
                  This platform may not be available in your country.
                </Typography>
              ) : (
                <HfSection>
                  <Grid container spacing={1}>
                    <Grid item>
                      <OpenInNewIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">Available in:</Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="caption">{countriesMarkup}</Typography>
                </HfSection>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <TabContext value={currentTab}>
              <div className={classes.tabsContainer}>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(_, newValue: TabEnum) => setCurrentTab(newValue)}
                  className={classes.tabs}
                >
                  {platform.anyAiringNow && (
                    <Tab
                      label="Airing now"
                      id="airing-now"
                      value={TabEnum.AiringNow}
                      className={clsx(
                        currentTab === TabEnum.AiringNow && classes.currentTab
                      )}
                      onClick={() => {
                        localStorage.setItem("tab", "airing");
                      }}
                    />
                  )}
                  {platform.anyComingSoon && (
                    <Tab
                      label="Coming soon"
                      id="coming-soon"
                      value={TabEnum.ComingSoon}
                      className={clsx(
                        currentTab === TabEnum.ComingSoon && classes.currentTab
                      )}
                      onClick={() => {
                        localStorage.setItem("tab", "coming");
                      }}
                    />
                  )}
                  <Tab
                    label="Top 120"
                    id="discover"
                    value={TabEnum.Discover}
                    className={clsx(
                      currentTab === TabEnum.Discover && classes.currentTab
                    )}
                    onClick={() => {
                      localStorage.setItem("tab", "discover");
                    }}
                  />
                </Tabs>
              </div>
              {platform.anyAiringNow && (
                <TabPanel value={TabEnum.AiringNow} style={{ width: "100%" }}>
                  <AiringNow
                    platform={platform}
                    displayType={currentDisplayType}
                    onDisplayTypeChange={onDisplayTypeChange}
                  />
                </TabPanel>
              )}
              {platform.anyComingSoon && (
                <TabPanel value={TabEnum.ComingSoon} style={{ width: "100%" }}>
                  <ComingSoon
                    platform={platform}
                    displayType={currentDisplayType}
                    onDisplayTypeChange={onDisplayTypeChange}
                  />
                </TabPanel>
              )}
              <TabPanel value={TabEnum.Discover} style={{ width: "100%" }}>
                <OtherShows
                  platform={platform}
                  displayType={currentDisplayType}
                  onDisplayTypeChange={onDisplayTypeChange}
                />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

function AiringNow({
  platform,
  displayType,
  onDisplayTypeChange,
}: SectionProps) {
  const { airingNow, loading } = useAiringNow(platform.name, 60);
  useLoading(loading);
  useDocumentTitle({ prefix: "Airing now", title: platform.title });

  if (airingNow.edges) {
    const shows = airingNow.edges.map((edge) => edge?.node as Show) || [];
    return (
      // <ShowsList
      //   emptyState={
      //     <EmptyState
      //       text={`Nothing is airing on ${platform.title} right now. Check back later!`}
      //     />
      //   }
      //   key="airing-now"
      //   title="Airing now"
      //   connection={airingNow}
      // />
      <ShowsList
        displayType={displayType}
        onDisplayTypeChange={onDisplayTypeChange}
        key="airing-now"
        shows={shows}
        // onSearch={() => {}}
      />
    );
  }

  return null;
}

function ComingSoon({
  platform,
  displayType,
  onDisplayTypeChange,
}: SectionProps) {
  const { comingSoon, loading } = useComingSoon(platform.name, 30);
  useLoading(loading);
  useDocumentTitle({ prefix: "Coming soon", title: platform.title });

  if (comingSoon.edges) {
    const shows = comingSoon.edges.map((edge) => edge?.node as Show) || [];

    return (
      <ShowsList
        displayType={displayType}
        onDisplayTypeChange={onDisplayTypeChange}
        key="coming-soon"
        shows={shows}
        // onSearch={() => {}}
      />
    );
  }

  return null;
}

function OtherShows({
  platform,
  displayType,
  onDisplayTypeChange,
}: SectionProps) {
  const { otherShows, loading } = useOtherShows(platform.name, 120);
  useLoading(loading);
  useDocumentTitle({ prefix: "Top 120", title: platform.title });

  if (otherShows.edges) {
    const shows = otherShows.edges.map((edge) => edge?.node as Show) || [];

    return (
      <ShowsList
        displayType={displayType}
        onDisplayTypeChange={onDisplayTypeChange}
        key="other-shows"
        shows={shows}
        // onSearch={() => {}}
      />
    );
  }

  return null;
}

/*function ShowsList({
  title,
  emptyState,
  connection,
  loading,
  showLoadMore,
  hasMoreResults,
  allowSearch,
  onFetchMore,
}: {
  title: string;
  emptyState: ReactNode;
  connection: ShowConnection;
  loading?: boolean;
  showLoadMore?: boolean;
  hasMoreResults?: boolean;
  allowSearch?: boolean;
  onFetchMore?: () => void;
}) {
  const classes = useStyles();

  const edges = useMemo(() => connection.edges || [], [connection.edges]);
  const [canShowMore, setCanShowMore] = useState(hasMoreResults);
  const [shows, setShows] = useState<Maybe<ShowEdge>[]>([]);
  const [input, setInput] = useState("");

  const query = useDebounceedValue(input, 500);

  useEffect(() => {
    setCanShowMore(query.trim().length === 0 && showLoadMore && hasMoreResults);
  }, [showLoadMore, hasMoreResults, edges, query]);

  useEffect(() => {
    if (query.trim().length) {
      const filteredShows = edges.filter((showEdge) => {
        const show = showEdge && showEdge.node && showEdge.node;
        const searchTerm = query.toLocaleLowerCase().trim();
        if (show) {
          return (
            show.titleRecord.en?.toLocaleLowerCase().includes(searchTerm) ||
            show.titleRecord.jp?.toLocaleLowerCase().includes(searchTerm) ||
            show.slug.toLocaleLowerCase().includes(searchTerm)
          );
        }

        return false;
      });

      setShows(filteredShows);
    } else {
      setShows(edges);
    }
  }, [query, edges]);

  if (edges.length === 0 && !loading) {
    return (
      <HfSection title={title}>
        <>{emptyState}</>
      </HfSection>
    );
  }

  return (
    <>
      <HfSection title={title}>
        {allowSearch && (
          <TextField
            className={classes.quickSearch}
            value={input}
            placeholder="Quick search..."
            onChange={(event) => setInput(event.target.value)}
          />
        )}
        <Grid container spacing={2}>
          {shows.map(
            (showEdge) =>
              showEdge &&
              showEdge.node && (
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Thumbnail show={showEdge.node} />
                </Grid>
              )
          )}
          {shows.length === 0 && (
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Skeleton staticAnimation title="No results were found :(" />
            </Grid>
          )}
        </Grid>
      </HfSection>
      {canShowMore && (
        <HfSection>
          <Button onClick={onFetchMore}>
            {loading ? <CircularProgress size={12} /> : "Show more..."}
          </Button>
        </HfSection>
      )}
    </>
  );
}
*/

function useAiringNow(name: string, first: number) {
  const result = useGetPlatformAiringNowQuery({
    variables: { name, top: first },
  });
  const { data } = result;

  if (data && data.platform?.airingNow) {
    return { ...result, airingNow: data.platform.airingNow as ShowConnection };
  }

  const pageInfo = {
    hasNextPage: false,
    totalCount: 0,
    hasPreviousPage: false,
    totalPages: 0,
  };

  return {
    ...result,
    airingNow: {
      edges: [],
      pageInfo,
    } as ShowConnection,
  };
}

function useComingSoon(name: string, first: number) {
  const result = useGetPlatformComingSoonQuery({
    variables: { name, top: first },
  });
  const { data } = result;

  if (data && data.platform?.comingSoon) {
    return {
      ...result,
      comingSoon: data.platform.comingSoon as ShowConnection,
    };
  }

  const pageInfo = {
    hasNextPage: false,
    totalCount: 0,
    hasPreviousPage: false,
    totalPages: 0,
  };

  return {
    ...result,
    comingSoon: {
      edges: [],
      pageInfo,
    } as ShowConnection,
  };
}

function useOtherShows(name: string, first: number) {
  const result = useGetPlatformOtherShowsQuery({
    variables: { name, top: first },
  });
  const { data } = result;

  if (data && data.platform?.otherShows) {
    return {
      ...result,
      otherShows: data.platform.otherShows as ShowConnection,
    };
  }

  const pageInfo = {
    hasNextPage: false,
    totalCount: 0,
    hasPreviousPage: false,
    totalPages: 0,
  };

  return {
    ...result,
    otherShows: {
      edges: [],
      pageInfo,
    } as ShowConnection,
  };
}
