import { Switch, Route } from "react-router-dom";
import { useCurrentUser } from "src/config";

import { Playground } from "src/sections";

export default function PlaygroundRouter() {
  const currentUser = useCurrentUser();

  if (!currentUser || !currentUser.active) {
    return null;
  }

  return (
    <Route exact path={["/play"]}>
      <Switch>
        <Route path="/play" exact component={Playground} />
      </Switch>
    </Route>
  );
}
