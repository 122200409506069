import { useContext } from "react";
import { BackgroundImageContext } from "src/config";

export function useBackgroundImage(imgUrl: string, opacity?: number) {
  const result = useContext(BackgroundImageContext);
  const { onImageAvailable } = result;

  onImageAvailable(imgUrl, opacity);
  return result;
}

export function useNoBackgroundImage() {
  return useBackgroundImage("", 0);
}
