import { useCallback, useEffect, useState } from "react";
import { useDocumentTitle } from "src/components/global";
import { getAPIHost } from "src/config";
import { useQueryParam } from "src/utils";

interface AuthToken {
  [key: string]: any;
}

export function OAuthCallback() {
  const code = useQueryParam("code");
  const [authToken, setAuthToken] = useState<AuthToken>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useDocumentTitle({ title: "Authorizing your request..." });

  const fetchAuthToken = useCallback(() => {
    if (loading || error) {
      return;
    }

    setLoading(true);
    fetch(getAPIHost(`/auth/misete/callback?code=${code}`), {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        setAuthToken(jsonData);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [code, loading, error]);

  useEffect(() => {
    fetchAuthToken();
  }, [fetchAuthToken]);

  useEffect(() => {
    if (!authToken) {
      return;
    }
    const { response } = authToken;
    if (response) {
      Object.keys(response).forEach((key) => {
        sessionStorage.setItem(`auth.${key}`, response[key]);
      });

      const nextPath = localStorage["next.path"] || "/";
      localStorage.removeItem("next.path");
      window.location.href = nextPath;
    }
  }, [authToken]);

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (loading) {
    return <p>Please wait...</p>;
  }

  return null;
}
