import {
  Chip,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import { getQueryParam, notEmpty, repeat } from "src/utils";
import { useLoading } from "src/config";
import { Skeleton } from "src/components/shows";
import {
  useGetTrendingShowsQuery,
  useSearchQuery,
  Scalars,
  FeaturedProp,
} from "src/generated/graphql";
import {
  useDocumentTitle,
  AutocompleteTagField,
  TagType,
} from "src/components/global";
import { ShowsList } from "../HomePage/components/ShowsList";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(6, 0),

    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(3, 3),
    },
  },
}));

export function SearchPage() {
  const query = getQueryParam("query");

  if (query.length) {
    return (
      <Container>
        <SearchFor />
      </Container>
    );
  }

  return (
    <Container>
      <ShowOnlyTopShows />
    </Container>
  );
}

function ShowOnlyTopShows() {
  const { selectedTags } = useSelectionTags();
  const { data, loading, error } = useGetTrendingShowsQuery({
    variables: {
      tags: selectedTags,
    },
  });
  useLoading(loading);
  useDocumentTitle({ title: "All-time best shows" });

  if (error) {
    console.error(error);
    return (
      <Wrapper title="): Uh oh, something didn't go well">
        <Grid container spacing={3}>
          {repeat(3, (itemKey) => (
            <Grid key={itemKey} item xs={6} sm={4} md={2}>
              <Skeleton error />
            </Grid>
          ))}
        </Grid>
      </Wrapper>
    );
  }

  const shows =
    data?.trending?.edges?.map((edge) => edge && edge.node).filter(notEmpty) ||
    [];

  if (shows.length > 0) {
    return (
      <ShowsList
        title="Best anime shows of all time"
        limitBasedOnWindowSize
        showPropsAsBadge={[FeaturedProp.Year, FeaturedProp.FriendlyStatus]}
        shows={shows}
        xs={6}
        sm={4}
        md={3}
        lg={2}
      />
    );
  }

  return null;
}

function SearchFor() {
  const history = useHistory();
  const query = getQueryParam("query");
  const { selectedTags } = useSelectionTags();

  const { loading, data, error } = useSearchQuery({
    variables: { query, tags: selectedTags },
  });
  useLoading(loading);
  useDocumentTitle({ title: `Search for "${query}"` });

  if (error) {
    console.error(error);
    return (
      <Wrapper>
        <p>Error while search for "{query}"</p>
      </Wrapper>
    );
  }

  if (data && data.search && data.search.edges) {
    const queryMarkup = query.length > 2 && (
      <Chip label={`"${query}"`} onDelete={() => history.push("/")} />
    );

    const titleMarkup = <span>Search results {queryMarkup}</span>;
    const shows = data.search.edges
      .map((edge) => edge && edge.node)
      .filter(notEmpty);

    if (shows.length > 0) {
      return (
        <ShowsList
          title={titleMarkup}
          showPropsAsBadge={[FeaturedProp.Year, FeaturedProp.FriendlyStatus]}
          shows={shows}
          xs={6}
          sm={4}
          md={3}
          lg={2}
        />
      );
    }

    return (
      <ShowsList.Skeleton
        title="Nothing was found :("
        staticAnimation
        limit={10}
        xs={6}
        sm={4}
        md={3}
        lg={2}
      />
    );
  }

  return <ShowsList.Skeleton limit={18} xs={6} sm={4} md={3} lg={2} />;
}

const Wrapper = ({
  title,
  onSelectionChange,
  children,
}: {
  title?: string;
  onSelectionChange?: (tags: TagType[]) => void;
  children: ReactNode;
}) => {
  const query = getQueryParam("query");
  const classes = useStyles();
  const history = useHistory();

  const queryMarkup = query.length > 2 && (
    <Chip label={`"${query}"`} onDelete={() => history.push("/")} />
  );

  const titleMarkup = title ? title : <span>Search results {queryMarkup}</span>;

  return (
    <Container className={classes.wrapper}>
      <Typography variant="h5">{titleMarkup}</Typography>
      {onSelectionChange && (
        <AutocompleteTagField onSelectionChange={onSelectionChange} />
      )}
      <div className={classes.wrapper}>{children}</div>
    </Container>
  );
};

function useSelectionTags() {
  const [selectedTags, setSelectedTags] = useState<Scalars["TagFilter"][]>([]);

  const onSelectionChange = (tags: TagType[]) => {
    setSelectedTags(tags.map((tag) => tag.value));
  };

  return {
    selectedTags,
    setSelectedTags,
    onSelectionChange,
  };
}
