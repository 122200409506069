import { useNoBackgroundImage } from "../hooks/useBackgroundImage";
import { useDocumentTitle } from "src/components/global";
import { Hero } from "./components/Hero";
import { notEmpty, useScrollListeners } from "src/utils";
import { useRef } from "react";
import {
  HomePageCategory,
  useGetHomePageCategoriesQuery,
} from "src/generated/graphql";
import { useIsAuthenticated, useLoading } from "src/config";
import { ShowsList } from "./components/ShowsList";
import { CircularProgress, Container, makeStyles } from "@material-ui/core";
import { CategoryShowsList } from "./components/CategoryShowsList";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export function HomePage() {
  const classes = useStyles();
  const shouldAuthRequest = useIsAuthenticated();
  const currentCategoriesRef = useRef<HomePageCategory[]>([]);
  useNoBackgroundImage();
  useDocumentTitle({ title: "Welcome" });

  const { loading, data, fetchMore } = useGetHomePageCategoriesQuery({
    variables: { first: 4 },
    context: {
      headers: {
        "X-Should-Auth": shouldAuthRequest ? "true" : "false",
      },
    },
  });

  const categories =
    data?.homePageCategories.edges
      ?.map((edge) => edge!.node)
      .filter(notEmpty) || [];

  useLoading(loading);

  useScrollListeners(
    null,
    () => {
      if (data?.homePageCategories.pageInfo.hasNextPage) {
        fetchMore({
          variables: {
            after: data.homePageCategories.pageInfo.endCursor,
          },
        });
      }
    },
    { offset: 200 }
  );

  if (!loading && categories.length) {
    currentCategoriesRef.current = categories as HomePageCategory[];
  }

  const loadingMarkup = (
    <>
      <ShowsList.Skeleton limitBasedOnWindowSize xs={6} sm={4} md={3} lg={2} />
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    </>
  );

  return (
    <main>
      <Hero />
      <Container>
        {currentCategoriesRef.current.map((category) => (
          <CategoryShowsList
            allowCache={
              !["your-watch-list", "following-on-discord"].includes(
                category.key
              )
            }
            allowViewMore
            limitBasedOnWindowSize
            slug={category.key}
            layout={category.layout}
            title={category.title}
          />
        ))}
        {loading && loadingMarkup}
      </Container>
    </main>
  );
}

// function WatchListShowsList() {
//   const { watchList, data, loading } = useWatchList({ first: 6 });

//   if (loading) {
//     <ShowsList.Skeleton
//       title="Loading your watch List..."
//       key="watch-list"
//       limitBasedOnWindowSize
//       xs={6}
//       sm={4}
//       md={3}
//       lg={2}
//     />;
//   }

//   return (
//     <ShowsList
//       allowViewMore
//       shows={watchList}
//       totalCount={data?.shows.pageInfo.totalCount}
//       title="Your watch list"
//       showPropsAsBadge={[FeaturedProp.Year, FeaturedProp.FriendlyStatus]}
//       key="watch-list"
//       limitBasedOnWindowSize
//       viewMoreLink={`/watch-list`}
//       xs={6}
//       sm={4}
//       md={3}
//       lg={2}
//     />
//   );
// }
