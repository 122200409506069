import { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import MaterialSkeleton from "@material-ui/lab/Skeleton";

type Props = {
  children: NonNullable<ReactNode>;
  hideTitle?: boolean;
  title?: ReactNode | null;
  loading?: boolean | null;
  typography?: TypographyProps;
  primaryAction?: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  hfSection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    width: "100%",

    // "&:first-child": {
    //   marginTop: theme.spacing(),
    // },
    // "&:last-child": {
    //   marginBottom: theme.spacing(),
    // },
  },
  hfSectionTitleContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  hfSectionTitle: {},
  hfSectionLink: {
    cursor: "pointer",
    textDecoration: "none",
  },
}));

export function HfSection({
  children,
  title,
  hideTitle,
  loading,
  primaryAction,
}: Props) {
  const classes = useStyles();
  const titleMarkup = loading ? (
    <MaterialSkeleton variant="text" height={40} width={300} />
  ) : (
    title
  );

  return (
    <div className={classes.hfSection}>
      {!hideTitle && (
        <div className={classes.hfSectionTitleContainer}>
          <Typography
            variant="h5"
            component="h1"
            className={classes.hfSectionTitle}
          >
            {titleMarkup}
          </Typography>
          {primaryAction}
        </div>
      )}
      {children}
    </div>
  );
}
