import React, { useContext, useEffect, useState } from "react";
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
// import Typography from "@material-ui/core/Typography";
// import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
// import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import GoToDefaultThemeIcon from "@material-ui/icons/Brightness7";
import GoToDarkThemeIcon from "@material-ui/icons/Brightness4";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CodeIcon from "@material-ui/icons/Code";
import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";
import { openInNewTab, useDebounceedValue } from "src/utils";
import { Show, useSearchLazyQuery } from "src/generated/graphql";
import {
  Avatar,
  Chip,
  CircularProgress,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  YourAnimeThemeContext,
  SessionContext,
  RequireAuthenticated,
} from "src/config";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("md")]: {
        backgroundColor: "#131313c0",
        transition: "background-color .5s ease-out",
        "&:hover": {
          backgroundColor: "#131313",
        },
      },
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    autocompleteList: {
      position: "absolute",
      width: "340px",
      backgroundColor: theme.palette.background.paper,
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    hidden: {
      display: "none",
    },
  })
);

export function PrimarySearchAppBar() {
  const classes = useStyles();
  const session = useContext(SessionContext);
  const isLoggedIn = Boolean(session.valid && session.user);

  const [input, onInputChange] = useState("");

  const history = useHistory();
  const goToHomePage = () => history.push("/");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const buttonsProps = {
    anchorEl,
    setAnchorEl,
  };

  const { isDarkTheme, onToggle } = useContext(YourAnimeThemeContext);
  const goToPlayground = () => openInNewTab("/play");
  const themeSwitcherDesktopMarkup = isDarkTheme ? (
    <IconButton
      aria-label="swith to light theme"
      color="inherit"
      onClick={onToggle}
    >
      <GoToDefaultThemeIcon />
    </IconButton>
  ) : (
    <IconButton
      aria-label="switch to dark theme"
      color="inherit"
      onClick={onToggle}
    >
      <GoToDarkThemeIcon />
    </IconButton>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" color="inherit" className={classes.root}>
        <Toolbar>
          <IconButton
            edge="start"
            onClick={goToHomePage}
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <HomeIcon />
          </IconButton>
          {/* <Typography className={classes.title} variant="h6" noWrap>
            <IconButton
              onClick={goToHomePage}
              aria-label="home page"
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Typography> */}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <SearchForShowsInput input={input} onInputChange={onInputChange} />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <RequireAuthenticated>
              <IconButton
                aria-label="go to API playground"
                color="inherit"
                onClick={goToPlayground}
              >
                <CodeIcon />
              </IconButton>
            </RequireAuthenticated>
            {themeSwitcherDesktopMarkup}

            {isLoggedIn ? (
              <AuthenticatedMenuButtonsDesktop {...buttonsProps} />
            ) : (
              <NotAuthenticatedMenuButtonsDesktop />
            )}
          </div>
          <div className={classes.sectionMobile}>
            {themeSwitcherDesktopMarkup}
            {isLoggedIn ? (
              <AuthenticatedMenuButtonsMobile />
            ) : (
              <NonAuthenticatedMenuButtonsMobile />
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

function AuthenticatedMenuButtonsDesktop({
  anchorEl,
  setAnchorEl,
}: {
  anchorEl: HTMLElement | null;
  setAnchorEl: (e: HTMLElement | null) => void;
}) {
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { onLogout, user: currentUser, goToUserAccount } = useContext(
    SessionContext
  );

  const isMenuOpen = Boolean(anchorEl);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={goToUserAccount}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        Go to Account details{" "}
        <OpenInNewIcon style={{ fontSize: 14, marginLeft: 6 }} />
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          onLogout();
        }}
      >
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <LogoutIcon />
        </IconButton>
        Log out
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {currentUser && (
        <IconButton onClick={handleProfileMenuOpen}>
          <Avatar src={currentUser.image} style={{ width: 24, height: 24 }} />
        </IconButton>
      )}
      {renderMenu}
    </>
  );
}

function NotAuthenticatedMenuButtonsDesktop() {
  const { onLogin } = useContext(SessionContext);

  return (
    <>
      <Button onClick={onLogin} color="inherit">
        Log in
      </Button>
    </>
  );
}

function AuthenticatedMenuButtonsMobile() {
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = React.useState<null | HTMLElement>(null);

  const { onLogout, loading, user: currentUser, goToUserAccount } = useContext(
    SessionContext
  );

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={goToUserAccount}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>
          Account details
          <OpenInNewIcon style={{ fontSize: 14, marginLeft: 6 }} />
        </p>
      </MenuItem>
      <MenuItem onClick={onLogout}>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <LogoutIcon />
        </IconButton>
        <p>{loading ? "Please wait..." : "Log out"}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <IconButton
        aria-label="show more"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
        color="inherit"
      >
        {currentUser && (
          <Avatar src={currentUser.image} style={{ width: 24, height: 24 }} />
        )}
      </IconButton>
      {renderMobileMenu}
    </>
  );
}

// function NonAuthenticatedMenuButtonsDesktop() {}

function NonAuthenticatedMenuButtonsMobile() {
  return <></>;
}

function SearchForShowsInput({
  input,
  onInputChange,
}: {
  input: string;
  onInputChange: (input: string) => void;
}) {
  const classes = useStyles();
  const history = useHistory();
  const previewResultsCount = 10;

  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState<Show[]>([]);
  const query = useDebounceedValue(input, 500);

  const [search, { data, loading }] = useSearchLazyQuery({
    variables: { query },
  });

  useEffect(() => {
    if (data && data.search && data.search.edges) {
      const showEdges = data.search.edges;
      const shows = showEdges.map((edge) => edge?.node as Show);
      setResults(shows);
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setResults([]);
    }
  }, [loading]);

  useEffect(() => {
    if (input === "") {
      setResults([]);
    }
  }, [input]);

  useEffect(() => {
    if (query.length) {
      search({ variables: { query } });
    }
  }, [query, search]);

  const goToSearchPage = () => {
    setShowResults(false);
    history.push(`/search?query=${encodeURIComponent(query)}`);
  };

  const hasQuery = Boolean(query && query.length);
  const queryChipMarkup = hasQuery && <Chip label={`"${query}"`} />;

  const loadingMarkup = loading && (
    <ListItemText>
      <CircularProgress size={14} style={{ marginRight: 8 }} /> Searching for{" "}
      {queryChipMarkup}...
    </ListItemText>
  );

  const noResultsMarkup = hasQuery &&
    query.length >= 3 &&
    results.length === 0 &&
    !loading && (
      <ListItemText>No results were found for {queryChipMarkup}</ListItemText>
    );

  const moreResultsCount = results.length > previewResultsCount && (
    <span>(+{results.length - previewResultsCount})</span>
  );

  const viewAllResultsMarkup = hasQuery && results.length > 0 && !loading && (
    <ListItemText>
      All results for <Chip label={`"${query}"`} /> {moreResultsCount}
    </ListItemText>
  );

  const helpMarkup = (!hasQuery || query.length < 3) && !loading && (
    <ListItemText>You can search in English or in Japanese...</ListItemText>
  );

  return (
    <div
      style={{ height: "100%" }}
      onBlur={(event) => {
        console.log(event.relatedTarget);
        if (!event.relatedTarget) {
          setShowResults(false);
        }
      }}
    >
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={(event) => onInputChange(event.target.value.trim())}
        onFocus={() => setShowResults(true)}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !loading) {
            goToSearchPage();
          }
        }}
      />
      <List
        className={clsx(
          classes.autocompleteList,
          !showResults && classes.hidden
        )}
      >
        {results.slice(0, 10).map((show) => {
          const labelId = `result-item-${show.slug}`;

          return (
            <ListItem
              key={show.slug}
              button
              onClick={() => {
                history.push(`/shows/${show.slug}`);
                setShowResults(false);
              }}
            >
              <ListItemAvatar>
                <Avatar alt={show.title} src={show.posterUrl} />
              </ListItemAvatar>

              <ListItemText id={labelId} primary={show.title} />
            </ListItem>
          );
        })}
        {query && <Divider />}
        <ListItem
          key="more-results"
          button
          onClick={() => {
            if (query && results.length) {
              goToSearchPage();
            }
          }}
        >
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          {loadingMarkup}
          {viewAllResultsMarkup}
          {noResultsMarkup}
          {helpMarkup}
        </ListItem>
      </List>
    </div>
  );
}
