import { Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Tag } from "src/generated/graphql";
import { useTags } from "src/hooks";

export declare type TagType = Pick<Tag, "value">;

interface Props {
  onSelectionChange: (tags: TagType[]) => void;
}

export function AutocompleteTagField({ onSelectionChange }: Props) {
  const { tags, loading } = useTags();
  const [currentTags, setCurrentTags] = useState<TagType[]>([]);

  useEffect(() => {
    if (!loading) {
      onSelectionChange(currentTags);
    }
  }, [loading, currentTags, onSelectionChange]);

  if (loading) {
    return (
      <TextField disabled placeholder="Loading tags..." variant="outlined" />
    );
  }

  return (
    <Autocomplete
      multiple
      id="fixed-tags-demo"
      value={currentTags}
      onChange={(_, newValue) => {
        setCurrentTags(newValue);
      }}
      options={tags}
      getOptionLabel={(option) => option.value}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip label={option.value} {...getTagProps({ index })} />
        ))
      }
      style={{ width: "auto" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Fixed tag"
          variant="outlined"
          placeholder="Filter by tags..."
        />
      )}
    />
  );
}
