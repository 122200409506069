import React, { useContext, useEffect } from "react";
import { ReactNode, useState } from "react";

interface Props {
  children: ReactNode;
}

export function LoadingStateProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingStateContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingStateContext.Provider>
  );
}

export const LoadingStateContext = React.createContext({
  loading: false,
  setLoading: (_: boolean) => {},
});

export function useLoading(loading?: boolean) {
  const { setLoading } = useContext(LoadingStateContext);

  useEffect(() => {
    if (loading !== undefined) {
      setLoading(loading);
    }
  });

  return { loading };
}
