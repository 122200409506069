import {
  Box,
  Paper,
  Tab,
  TabProps,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactNode, useState } from "react";
import { useHistory } from "react-router";

import SwipeableViews from "react-swipeable-views";
import { useQueryParam } from "src/utils";

export interface CustomTab {
  tabProps: TabProps;
  body: ReactNode;

  // Tab will be default tab if equal query param string "on"
  defaultOn?: string;
}

interface Props {
  tabs: CustomTab[];
  renderAsLeast?: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme) => ({
  tabBox: {
    marginTop: theme.spacing(2),
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabBox}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function SwipeableTabs({ tabs, renderAsLeast }: Props) {
  const theme = useTheme();
  const history = useHistory();
  const defaultTabId = useQueryParam("on");
  const defaultTabIndex = tabs.findIndex(
    (tab) => tab.defaultOn === defaultTabId
  );
  const [currentTab, setCurrentTab] = useState(
    defaultTabIndex >= 0 ? defaultTabIndex : 0
  );

  const shouldRenderTabs = renderAsLeast ? tabs.length >= renderAsLeast : true;

  const onChangeIndex = (index: number) => {
    console.log(index);
    setCurrentTab(index);
    console.log(tabs[index]);
    if (tabs[index]?.defaultOn) {
      console.log("replacing on!");
      history.replace({ search: `?on=${tabs[index].defaultOn}` });
    }
  };

  return (
    <>
      {shouldRenderTabs && (
        <Paper square>
          <Tabs
            value={currentTab}
            onChange={(_event, value) => {
              onChangeIndex(value);
            }}
            indicatorColor="primary"
            textColor="primary"
            aria-label="show info tabs"
            variant="fullWidth"
          >
            {tabs.map((tab) => (
              <Tab {...tab.tabProps} {...a11yProps(0)} />
            ))}
          </Tabs>
        </Paper>
      )}
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={currentTab}
        onChangeIndex={onChangeIndex}
      >
        {tabs.map((tab, index) => (
          <TabPanel value={currentTab} index={index} dir={theme.direction}>
            {tab.body}
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
}
