import { Container, makeStyles, Typography } from "@material-ui/core";
import { useCurrentUser, useLoading } from "src/config";
import { Link, SupportLink } from "../components";
import { useBackgroundImage } from "../hooks/useBackgroundImage";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    width: "100%",
    maxWidth: 800,
    height: "calc(100vh - 12rem - 64px)",
    placeItems: "center",
    placeContent: "center",
    textAlign: "center",
  },
  title: {
    width: "100%",
    textAlign: "center",
  },
  description: {
    marginTop: theme.spacing(2),
  },
}));

export function NotFound() {
  const classes = useStyles();
  useLoading(false);

  const currentUser = useCurrentUser();

  useBackgroundImage(
    "https://misete.s3.us-east-2.amazonaws.com/img/youranime-404.jpg"
  );

  const description = currentUser ? (
    <>
      Mitsukanu Onee-chan speaking! I looked everywhere and couldn't find
      anything {currentUser.first_name}-chan 🥺. Are you sure this is what you
      were looking for? Onee-chan suggests that you go back to{" "}
      <Link to="/">the home page</Link>. If this is still not working, be sure
      to let <SupportLink>Onee-chan</SupportLink> know, okay{" "}
      {currentUser.first_name}-san?
    </>
  ) : (
    <>
      Hey, there! We looked everywhere and couldn't find anything 🥺. This page
      might not exist anymore. We suggest that you go back to{" "}
      <Link to="/">the home page</Link>. If this still doesn't work, be sure to
      let <SupportLink>us</SupportLink> know!
    </>
  );

  return (
    <Container className={classes.wrapper}>
      <div>
        <Typography variant="h4" className={classes.title}>
          Aiya, looks like you're lost! 🤔
        </Typography>
        <Typography variant="overline" className={classes.description}>
          {description}
        </Typography>
        <br />
        <Typography variant="subtitle2" className={classes.description}>
          This page was not found.
        </Typography>
      </div>
    </Container>
  );
}
