import { useQuery } from "@apollo/client";
import {
  CircularProgress,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { gql } from "apollo-boost";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useBackgroundImage } from "src/sections/hooks/useBackgroundImage";

interface CountryInfo {
  [key: string]: any;
}

const pingQuery = gql`
  query PingCountryInfo {
    countryTimezone {
      country
      timezone
      isDefault
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100vh",
    placeItems: "center",
    placeContent: "center",
    textAlign: "center",
  },
  title: {
    width: "100%",
    textAlign: "center",
  },
  description: {
    marginTop: theme.spacing(2),
  },
}));

export function PingContext({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();
  const { data, loading, error } = useQuery(pingQuery, {
    context: { test: true },
  });

  const [wallpaper, setWallpaper] = useState("");
  useBackgroundImage(wallpaper);

  const [loadingText, setLoadingText] = useState(
    "Sit tight! We're making sure everything's in order..."
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadingText("We're almost done!");
    }, 7000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (error) {
      setWallpaper(
        "https://misete.s3.us-east-2.amazonaws.com/img/youranime-500.jpg"
      );
    }
  }, [error]);

  if (error) {
    console.error(error);
    return (
      <Container className={classes.root} style={{ maxWidth: 800 }}>
        <div>
          <Typography variant="h4" className={classes.title}>
            Dio did it again. We're sorry. 🙇💦
          </Typography>
          <Typography variant="overline" className={classes.description}>
            **SIGH** You were expecting to see cultured information... So did
            we. Ahh, he's enjoying this too much right now, that's not good.
            Please try again a bit later. We are doing our very best to clean up
            the mess on our end.
          </Typography>
          <br />
          <Typography variant="subtitle2" className={classes.description}>
            This is an Internal Server Error.
          </Typography>
        </div>
      </Container>
    );
  }

  if (loading || !data) {
    return (
      <div className={classes.root}>
        <div>
          <CircularProgress />
          <Typography className={classes.description}>{loadingText}</Typography>
        </div>
      </div>
    );
  }

  return (
    <Context.Provider value={{ info: data.countryTimezone }}>
      {children}
    </Context.Provider>
  );
}

const Context = React.createContext({
  info: {} as CountryInfo,
});

export function useCountryInfo() {
  const { info } = useContext(Context);

  return info;
}
