import { Chip, Grid, makeStyles, useTheme } from "@material-ui/core";
import { PropsWithChildren, useState } from "react";
// import { useHistory } from "react-router-dom";
import { Tag } from "src/generated/graphql";
import { getContrastYIQ } from "src/utils";
// import { getContrastYIQ } from "src/utils";

interface WithColour {
  colour?: string | null;
}
interface WithOnClick {
  onClick?: () => void;
}

type TagLike = Pick<Tag, "value">;

export type TagItem = (string | TagLike) & WithColour & WithOnClick;

const useStyles = makeStyles((theme) => ({
  tagsWrapper: {
    padding: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  tagsContainer: {
    marginBottom: theme.spacing(),
  },
  tag: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}));

export function TagsList({
  items,
  itemName,
  noTagsText,
  limit,
  allowShowAll,
}: {
  items: TagItem[];
  itemName?: { plural: string; singular: string };
  noTagsText?: string;
  limit?: number;
  allowShowAll?: boolean;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [showAllTags, setShowAllTags] = useState(false);

  const tagsToShow = showAllTags
    ? items
    : limit && limit > 0
    ? items.slice(0, limit)
    : items;
  const remainingTags =
    limit && items.length > limit ? items.length - limit : undefined;

  const singularPluralKey =
    remainingTags && remainingTags < 2 ? "singular" : "plural";
  const moreTagsItemName = (itemName && itemName[singularPluralKey]) || "tags";

  const moreTagsText = allowShowAll
    ? `View +${remainingTags} ${moreTagsItemName}`
    : `+${remainingTags} ${moreTagsItemName}`;

  const moreTagsMarkup =
    !showAllTags && remainingTags ? (
      <Chip
        disabled={!Boolean(allowShowAll)}
        key="more-tags"
        size="small"
        label={moreTagsText}
        onClick={() => setShowAllTags(!showAllTags)}
        style={{ backgroundColor: theme.palette.info[theme.palette.type] }}
        className={classes.tag}
      />
    ) : null;

  const ChipForString = ({
    item,
    style,
    onClick,
  }: {
    item: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  }) => (
    <Chip
      key={item}
      size="small"
      label={item}
      className={classes.tag}
      onClick={onClick}
      style={style}
    />
  );

  const ChipForItem = ({ item }: { item: TagItem }) => {
    const style = item.colour
      ? {
          backgroundColor: item.colour,
          color: getContrastYIQ(item.colour),
        }
      : {};

    if ((item as Tag).value) {
      return (
        <ChipForString
          item={(item as Tag).value}
          onClick={item.onClick}
          style={style}
        />
      );
    }

    return (
      <ChipForString
        item={item as string}
        onClick={item.onClick}
        style={style}
      />
    );
  };

  const tagsMarkup =
    items.length > 0
      ? tagsToShow.map((tag) => <ChipForItem item={tag} />)
      : noTagsText && (
          <Chip
            disabled
            key="no-tags"
            size="small"
            label={noTagsText}
            className={classes.tag}
          />
        );

  const Wrapper = ({ children }: PropsWithChildren<{}>) => (
    <Grid item xs={12} className={classes.tagsContainer}>
      {children}
    </Grid>
  );

  return (
    <Wrapper>
      <>
        {tagsMarkup}
        {moreTagsMarkup}
      </>
    </Wrapper>
  );
}
