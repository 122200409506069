// import React from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDocumentTitle } from "src/components/global";
import { PlatformIcon } from "src/components/global/PlatformIcon";
import { useLoading } from "src/config";
import { useGetPlatformsQuery } from "src/generated/graphql";
import { useNoBackgroundImage } from "../hooks/useBackgroundImage";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 0),

    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(3, 3),
    },
  },
  wrapper: {
    padding: theme.spacing(2),
  },
  card: {
    cursor: "pointer",
  },
}));

export function PlatformsPage() {
  const history = useHistory();
  const classes = useStyles();

  const { data, loading, error } = useGetPlatformsQuery();
  useLoading(loading);
  useNoBackgroundImage();
  useDocumentTitle({ title: "Browse platforms" });

  if (error || !data) {
    return <p>{error}</p>;
  }

  return (
    <Container className={classes.root} maxWidth="lg">
      <Typography component="h2" variant="h5">
        Streamable platforms
      </Typography>
      <Grid container spacing={1} className={classes.wrapper}>
        {data.topPlatforms.edges?.map((platformEdge) => {
          const platform = platformEdge?.node;
          if (!platform) {
            return null;
          }

          return (
            <Grid item xs={6}>
              <Card
                className={classes.card}
                onClick={() => history.push(`/platforms/${platform.name}`)}
              >
                <CardContent style={{ display: "flex" }}>
                  <PlatformIcon
                    platform={platform}
                    style={{ display: "flex", alignItems: "center" }}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      component="h2"
                      variant="h6"
                      style={{ paddingLeft: 10 }}
                    >
                      {platform.title}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      style={{ paddingLeft: 16 }}
                    >{`${platform.showsCount} shows・${platform.activeShowsCount} airing`}</Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
