import { useHistory } from "react-router";
import { Show } from "src/generated/graphql";
import { notEmpty } from "src/utils";
import { TagsList } from "./TagsList";

interface Props {
  show: Show;
  showPlatforms?: boolean;
  limit?: number;
  allowShowAll?: boolean;
}

export function ShowTagsList({
  show: { tags, links },
  limit,
  showPlatforms,
  allowShowAll,
}: Props) {
  const history = useHistory();

  const platforms = links
    ? links
        .map((link) => {
          const platform = link.platform;
          if (!platform) {
            return null;
          }

          return {
            value: platform.title,
            colour: platform.colour,
            onClick: () => history.push(`/platforms/${platform.name}`),
          };
        })
        .filter(notEmpty)
    : [];

  const items = showPlatforms ? platforms : tags;

  return (
    <TagsList
      allowShowAll={allowShowAll}
      items={items}
      noTagsText="- No tags -"
      limit={limit}
    />
  );
}
