import { Grid, makeStyles } from "@material-ui/core";
import { Show } from "src/generated/graphql";
import { ThumbnailList } from "./ThumbnailList";
import { DisplayTypeOption, UtilityTopComponent } from "./UtilityTopComponent";

interface ShowsListProps {
  shows: Show[];
  displayType: DisplayTypeOption;
  onDisplayTypeChange: (displayType: DisplayTypeOption) => void;
  onSearch?: (query: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  utilities: {
    width: "100%",
  },
  list: {
    width: "100%",
  },
}));

export function ShowsList({
  shows,
  displayType,
  onDisplayTypeChange,
  onSearch,
}: ShowsListProps) {
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item className={classes.utilities}>
        <UtilityTopComponent
          currentDisplayType={displayType}
          onSearchChange={onSearch}
          onDisplayTypeChange={onDisplayTypeChange}
        />
      </Grid>

      <Grid item className={classes.list}>
        <ThumbnailList displayType={displayType} shows={shows} />
      </Grid>
    </Grid>
  );
}
