import { CircularProgress, Container, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
// import { UnsafeThumbnail } from "src/components/shows";
import { useLoading } from "src/config";
import {
  HomePageCategory,
  useGetShowsForCategoryQuery,
} from "src/generated/graphql";
import { ShowsList } from "src/sections/HomePage/components/ShowsList";
import { notEmpty, useScrollListeners } from "src/utils";
interface Props {
  category: Omit<HomePageCategory, "shows">;
  allowCache?: boolean;
  cacheForUser?: boolean;
}

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export function ViewCategory({ category, allowCache, cacheForUser }: Props) {
  const classes = useStyles();
  const [fetchingMore, setFetchingMore] = useState(false);
  const initialLoadComplete = useRef(false);
  const { data, loading, fetchMore } = useGetShowsForCategoryQuery({
    variables: { slug: category.key, first: 24 },
    fetchPolicy: "cache-and-network",
    context: {
      headers: {
        "X-Allow-Cache": allowCache ? "true" : "false",
        "X-Should-Auth": cacheForUser ? "true" : "false",
      },
    },
  });
  useLoading(loading);

  useEffect(() => {
    if (initialLoadComplete.current) {
      return;
    }
    if (!loading) {
      initialLoadComplete.current = true;
    }
  }, [loading]);

  useScrollListeners(
    null,
    async () => {
      if (data?.homePageCategoryShows.pageInfo.hasNextPage) {
        try {
          setFetchingMore(true);
          await fetchMore({
            variables: {
              after: data.homePageCategoryShows.pageInfo.endCursor,
            },
          });
        } finally {
          setFetchingMore(false);
        }
      }
    },
    { offset: 200 }
  );

  const shows =
    data?.homePageCategoryShows.edges
      ?.map((edge) => edge?.node)
      .filter(notEmpty) || [];

  // if (shows.length) {
  //   currentShowsRef.current = shows;
  // }

  const loadingMarkup = !initialLoadComplete.current && loading && (
    <>
      <ShowsList.Skeleton limit={24} xs={6} sm={4} md={3} lg={2} />
    </>
  );

  const fetchingingMoreMarkup = fetchingMore && (
    <>
      <ShowsList.Skeleton partial limit={6} xs={6} sm={4} md={3} lg={2} />
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    </>
  );

  return (
    <Container key={category.key}>
      {loadingMarkup}
      <ShowsList
        {...category}
        showPropsAsBadge={category.featuredProps}
        shows={shows}
        xs={6}
        sm={4}
        md={3}
        lg={2}
      />
      {fetchingingMoreMarkup}
    </Container>
  );
}
