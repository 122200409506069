import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useContext, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { PrimarySearchAppBar } from "src/components/global/Toolbars";
import { Footer, useNoDocumentTitle } from "src/components/global";
import { LoadingStateContext, PingContext } from "src/config";
import {
  HomePage,
  ShowDetailsContainer,
  ShowPlayground,
  SearchPage,
  PlatformsPage,
  PlatformDetailsContainer,
  ViewCategory,
  // NotFound,
  ViewWatchList,
} from "src/sections";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    position: "relative",
    minHeight: "100vh",
  },
  pageContents: {
    paddingBottom: "13rem",
  },
  body: {
    marginTop: "calc(64px + 1rem)",
  },
}));

export default function AppRouter() {
  const classes = useStyles();
  const { loading } = useContext(LoadingStateContext);

  useNoDocumentTitle();

  return (
    <>
      <ScrollToTop />
      <div className={classes.pageWrapper}>
        <div className={classes.pageContents}>
          <CssBaseline />
          <PingContext>
            <PrimarySearchAppBar />
            {loading && (
              <div style={{ marginTop: 64 }}>
                <LinearProgress />
              </div>
            )}

            <div className={classes.body}>
              <AllAppRoutes />
            </div>
            <Footer />
          </PingContext>
        </div>
      </div>
    </>
  );
}

function AllAppRoutes() {
  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/shows/:slug" exact component={ShowDetailsContainer} />
      <Route path="/shows/:slug/play" exact component={ShowPlayground} />
      <Route path="/search" exact component={SearchPage} />
      <Route path="/platforms" exact component={PlatformsPage} />
      <Route
        path="/platforms/:name"
        exact
        component={PlatformDetailsContainer}
      />
      <Route
        path="/platforms/:name/:tab"
        exact
        component={PlatformDetailsContainer}
      />
      <Route path="/categories/:slug" exact component={ViewCategory} />
      <Route path="/watch-list" exact component={ViewWatchList} />
      {/* <Route component={NotFound} /> */}
    </Switch>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
