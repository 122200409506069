import { useEffect, useState } from "react";
import {
  AiringSchedule,
  Show,
  useNextAiringEpisodeQuery,
} from "src/generated/graphql";

interface Result {
  loading: boolean;
  airingSchedule: AiringSchedule | null;
  content: string | null;
  anyNextEpisodeAvailable: boolean;
}

export const useNextEpisodesAiring = (show: Show): Result => {
  const { loading, error, data } = useNextAiringEpisodeQuery({
    variables: { slug: show.slug },
  });

  const [airingSchedule, setAiringSchedule] = useState<AiringSchedule | null>(
    null
  );
  const [timeUntilAiring, setTimeUntilAiring] = useState(
    airingSchedule?.timeUntilAiring || -1
  );
  const [content, setContent] = useState("");

  const anyNextEpisodeAvailable = Boolean(
    loading || (data && data.nextAiringEpisode != null)
  );

  useEffect(() => {
    const airingSchedule =
      (!loading && !error && data && data.nextAiringEpisode) || null;
    setAiringSchedule(airingSchedule);
    if (airingSchedule) {
      setContent(getNextEpisodeText(airingSchedule.timeUntilAiring));
    }
  }, [loading, error, data]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeUntilAiring < 0 && airingSchedule) {
        setTimeUntilAiring(airingSchedule.timeUntilAiring);
      } else if (timeUntilAiring > 0) {
        setTimeUntilAiring(timeUntilAiring - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeUntilAiring, airingSchedule]);

  if (!airingSchedule || error || !data) {
    return {
      loading,
      airingSchedule: null,
      content: null,
      anyNextEpisodeAvailable: false,
    };
  }

  return {
    loading,
    airingSchedule,
    content,
    anyNextEpisodeAvailable,
  };
};

export const getNextEpisodeText = (
  timeUntilAiring: number,
  withSeconds?: boolean,
  removePrefix?: boolean,
) => {
  if (timeUntilAiring < 0) {
    return "";
  }

  if (timeUntilAiring === 0) {
    return "airing now!";
  }

  const text = removePrefix ? [] : ["in about"];

  let timeLeft = timeUntilAiring;

  const days = Math.floor(timeLeft / (24 * 60 * 60));
  timeLeft %= 24 * 60 * 60;

  if (days > 0) {
    text.push(`${days.toFixed(0)}d`);
  }

  const hours = Math.floor(timeLeft / (60 * 60));
  timeLeft %= 60 * 60;
  if (hours > 0) {
    text.push(`${hours.toFixed(0)}h`);
  }

  const minutes = Math.floor(timeLeft / 60);
  if (minutes > 0) {
    text.push(`${numberWithPaddedZero(minutes)}m`);
  }

  const seconds = Math.floor(timeLeft % 60);
  if (seconds >= 0 && withSeconds) {
    text.push(`${numberWithPaddedZero(seconds)}s`);
  }

  // Equivalent to:
  // `in about ${days}d and ${hours}h ${minutes}m ${seconds}s`;
  return text.join(" ");
};

function numberWithPaddedZero(num: number) {
  if (num > 9) {
    return num.toFixed(0);
  }

  return `0${num}`;
}
