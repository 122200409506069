import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { Thumbnail } from "src/components/shows";
import { FeaturedProp, Show } from "src/generated/graphql";
import { ShowTagsList } from "src/sections/ViewShow/components/ShowDetails/components";
import { DisplayTypeOption } from "./UtilityTopComponent";

interface Props {
  displayType: DisplayTypeOption;
  shows: Show[];
}

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: "100%",
  },
  bulletItem: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    // marginBottom: theme.spacing(),
  },
}));

export function ThumbnailList({ displayType, shows }: Props) {
  const classes = useStyles();

  const Wrapper = ({ children }: { children: ReactNode }) => {
    if (displayType === "bulleted") {
      return <List className={classes.listRoot}>{children}</List>;
    } else {
      return (
        <Grid container spacing={2}>
          {children}
        </Grid>
      );
    }
  };

  const ShowItem = ({ show }: { show: Show }) =>
    displayType === "bulleted" ? (
      <AsBulletedItem show={show} />
    ) : (
      <AsThumbnailItem show={show} />
    );

  return (
    <Wrapper>
      {shows.map((show) => (
        <ShowItem show={show} />
      ))}
    </Wrapper>
  );
}

function AsThumbnailItem({ show }: { show: Show }) {
  return (
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <Thumbnail
        showPropsAsBadge={[FeaturedProp.Year, FeaturedProp.FriendlyStatus]}
        show={show}
      />
    </Grid>
  );
}

function AsBulletedItem({ show }: { show: Show }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid item className={classes.bulletItem}>
      <ListItem button onClick={() => history.push(`/shows/${show.slug}`)}>
        <ListItemAvatar>
          <Avatar alt={show.title} src={show.posterUrl} />
        </ListItemAvatar>

        <ListItemText>
          <Typography>
            {show.title} ({show.year})
          </Typography>
          <ShowTagsList show={show} />
        </ListItemText>
      </ListItem>
    </Grid>
  );
}
