import { useParams } from "react-router";
import { useLoading } from "src/config";
import { useGetCategoryBySlugQuery } from "src/generated/graphql";
import { ViewCategory } from "./ViewCategory";

export function ViewCategoryContainer() {
  const { slug } = useParams<{ slug: string }>();
  const { data, loading } = useGetCategoryBySlugQuery({
    variables: { slug, first: 0 },
  });
  useLoading(loading);

  if (loading) {
    return null;
  }

  const category = data?.homePageCategory;
  if (category) {
    return (
      <ViewCategory
        allowCache={
          !["your-watch-list", "following-on-discord"].includes(category.key)
        }
        key={category.key}
        category={category}
      />
    );
  } else {
    window.location.assign("/");
  }

  return <p></p>;
}
