import { CircularProgress, Typography } from "@material-ui/core";
import { useContext } from "react";
import { Copyright } from "src/components/IP";
import { SessionContext, useLoading } from "src/config";
import { AuthenticatedFooter } from "./AuthenticatedFooter";
import { NotAuthenticatedFooter } from "./NotAuthenticatedFooter";
import useFooterStyles from "./hooks/useFooterStyles";

export function Footer() {
  const classes = useFooterStyles();
  const { valid: loggedIn, user } = useContext(SessionContext);
  const { loading: pageLoading } = useLoading();

  const footerMarkup =
    loggedIn && user ? (
      <AuthenticatedFooter user={user} />
    ) : (
      <NotAuthenticatedFooter />
    );

  return (
    <>
      <div className={classes.footerBackground} />
      <footer className={classes.footer}>
        {footerMarkup} {pageLoading && <CircularProgress />}
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          By <span className={classes.name}>Akinyele Cafe-Febrissy</span>
        </Typography>
        <Copyright />
      </footer>
    </>
  );
}
