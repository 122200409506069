import { Typography } from "@material-ui/core";
import { User } from "src/config";

interface Props {
  user: User;
}

export function AuthenticatedFooter({ user }: Props) {
  return (
    <Typography variant="h6" align="center" gutterBottom>
      Welcome, {user.name}
    </Typography>
  );
}
