import { Platform } from "src/generated/graphql";
import { platformIcon as getPlatformIcon } from "src/icons/platforms";

import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { Avatar, makeStyles, useTheme } from "@material-ui/core";
import { CSSProperties } from "react";

interface StyledProps {
  width?: number;
  height?: number;
  style?: CSSProperties;
}

interface BasicsProps {
  platform: Pick<Platform, "title" | "name">;
  rounded?: boolean;
  avatar?: boolean;
}

type Props = BasicsProps & StyledProps;

export function PlatformIcon({
  platform,
  avatar,
  rounded = true,
  width = 30,
  height = 30,
  style,
}: Props) {
  const platformIcon = getPlatformIcon(platform);
  const unknownPlatform = !Boolean(platformIcon);

  const classes = useDynamicStyles({
    rounded,
    unknownPlatform,
    options: { height, width, style },
  });

  if (unknownPlatform) {
    return <PlayCircleFilledIcon className={classes.unknownPlatformIcon} />;
  }

  if (avatar) {
    return (
      <Avatar
        alt={platform.title}
        src={platformIcon}
        className={classes.platformIcon}
      />
    );
  }

  return (
    <img
      alt={platform.title}
      src={platformIcon}
      className={classes.platformIcon}
    />
  );
}

function useDynamicStyles({
  unknownPlatform,
  rounded,
  options,
}: {
  rounded: boolean;
  unknownPlatform: boolean;
  options: StyledProps;
}) {
  const theme = useTheme();
  const { width, height, style } = options;
  const color =
    (unknownPlatform &&
      (theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black)) ||
    undefined;

  const borderRadius = rounded ? "100%" : 0;

  return makeStyles(() => ({
    platformIcon: {
      width,
      height,
      borderRadius,
      ...style,
    },
    unknownPlatformIcon: {
      width,
      height,
      color,
      borderRadius,
      ...style,
    },
  }))();
}
