import GraphiQL from "graphiql";
import { useAccessToken, useGraphQLUrl } from "src/config";
import { useBackgroundImage } from "../hooks/useBackgroundImage";
import { fetcher } from "../Playground";
import { loader } from "graphql.macro";
import { Show } from "src/generated/graphql";

import { print } from "graphql/language/printer";

export function ShowPlayground({ show }: { show: Show }) {
  const graphQLUri = useGraphQLUrl();
  const accessToken = useAccessToken();

  const query = loader("src/sections/ViewShow/graphql/GetShowBySlug.graphql");
  useBackgroundImage(show.bannerUrl);

  return (
    <div style={{ height: "40vh", minHeight: 400 }}>
      <GraphiQL
        docExplorerOpen={false}
        query={print(query)}
        defaultVariableEditorOpen={false}
        variables={JSON.stringify({ slug: show.slug })}
        fetcher={fetcher(graphQLUri, accessToken)}
      />
    </div>
  );
}
