import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { defaultDataIdFromObject } from "apollo-cache-inmemory";
import { ReactNode } from "react";

import ReactGA from "react-ga";

type Environment = "production" | "development" | "staging";

type Hosts = { [key in Environment]: string };

interface Props {
  children: ReactNode;
  env?: Environment;
  enabledGAEnvironments?: Environment[];
}

interface GraphQLObject {
  __typename?: string;
  [key: string]: any;
}

const nodeEnv =
  process.env.NODE_ENV === "test" ? "development" : process.env.NODE_ENV;

const chosenEnviroment = (process.env.API_ENV as Environment) || nodeEnv;

export const getAPIHost = (
  path?: string,
  options?: { from?: Environment; ignoreApiHost?: boolean }
) => {
  const hosts: Hosts = {
    staging: "https://tanoshimu-stg.herokuapp.com",
    development: "http://localhost:3001",
    production: "https://proxy.youranime.moe/graphql",
  };

  const host =
    process.env.REACT_APP_API_HOST && !options?.ignoreApiHost
      ? process.env.REACT_APP_API_HOST
      : hosts[options?.from || chosenEnviroment];

  return path ? new URL(path, host).toString() : host;
};

const uriFrom = (from?: Environment, ignoreApiHost?: boolean) => {
  return getAPIHost("/graphql", { from, ignoreApiHost });
};

const uri = uriFrom(chosenEnviroment);
console.log({ uri });

const dataIdFromObject = (object: GraphQLObject) => {
  switch (object.__typename) {
    case "HomePageCategory":
      return object.key;
    case "Show":
      return object.slug;
    default:
      return defaultDataIdFromObject(object);
  }
};

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem("auth.access_token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const link = authLink.concat(httpLink);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    dataIdFromObject,
    typePolicies: {
      Query: {
        fields: {
          homePageCategoryShows: relayStylePagination(),
          homePageCategories: relayStylePagination(),
        },
      },
    },
  }),
});

export function GraphQLProvider({ children, enabledGAEnvironments }: Props) {
  if (enabledGAEnvironments) {
    const anyEnabledEnvironments = enabledGAEnvironments.find((environment) =>
      uriFrom(environment, true)
    );
    if (
      anyEnabledEnvironments &&
      uri === uriFrom(anyEnabledEnvironments, true)
    ) {
      ReactGA.initialize("G-7H7T004WNX");
    }
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export function useGraphQLUrl() {
  return uri;
}
