import { CssBaseline } from "@material-ui/core";
import { Switch, Route } from "react-router-dom";
import { getAPIHost, YourAnimeThemeProvider } from "src/config";

import { Signup } from "src/sections";
import { OAuthCallback } from "src/sections/OAuthCallback";

export default function AuthenticationRouter() {
  console.log("auth url", getAPIHost("/authorize"));

  return (
    <>
      <Route
        exact
        path={["/signup", "/authorize", "/my-account", "/auth/misete/callback"]}
      >
        <YourAnimeThemeProvider>
          <CssBaseline />
          <Switch>
            <Route path="/signup" exact component={Signup} />
            <Route
              path="/authorize"
              exact
              component={() => {
                window.location.href = getAPIHost("/authorize");
                return null;
              }}
            />
            <Route
              path="/my-account"
              exact
              component={() => {
                window.location.href = getAPIHost("/my-account");
                return null;
              }}
            />
            <Route
              path="/auth/misete/callback"
              exact
              component={OAuthCallback}
            />
          </Switch>
        </YourAnimeThemeProvider>
      </Route>
    </>
  );
}
