import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A valid representation of a country. */
  Country: any;
  Dimensions: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** A loose key-value map in GraphQL */
  Map: any;
  /** A valid representation of a region locked scalar. If true, it returns the {for_country: string}. */
  RegionLocked: any;
  TagFilter: any;
};

export type AgeRating = {
  __typename?: 'AgeRating';
  guide?: Maybe<Scalars['String']>;
  rating: Scalars['String'];
};

export type AiringSchedule = {
  __typename?: 'AiringSchedule';
  airingAt: Scalars['Int'];
  episodeNumber: Scalars['Int'];
  timeUntilAiring: Scalars['Int'];
};

/** The airing status of a show. Enum values. */
export enum AiringStatus {
  ComingSoon = 'COMING_SOON',
  Current = 'CURRENT',
  Finished = 'FINISHED',
  Tba = 'TBA',
  Unreleased = 'UNRELEASED',
  Upcoming = 'UPCOMING'
}


export type CountryTimezone = {
  __typename?: 'CountryTimezone';
  country: Scalars['Country'];
  isDefault: Scalars['Boolean'];
  timezone: Scalars['String'];
};


export enum FeaturedProp {
  AiringAt = 'AIRING_AT',
  FriendlyStatus = 'FRIENDLY_STATUS',
  NextEpisode = 'NEXT_EPISODE',
  Year = 'YEAR'
}

export enum Filter {
  /** Sort a show by it's airing date, i.e. when it's next airing. */
  AiringAt = 'AIRING_AT',
  /** Sort a show by popularity (rank). The smaller the popularity, the more popular it is. */
  Popularity = 'POPULARITY',
  /** Sort a show by it's start date, i.e. when it started airing. */
  StartDate = 'START_DATE',
  /** Sort a show by title. */
  Title = 'TITLE'
}

export type HomePageCategory = {
  __typename?: 'HomePageCategory';
  canFetchMore: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  featuredProps: Array<FeaturedProp>;
  key: Scalars['String'];
  layout: Layout;
  shows: ShowConnection;
  title: Scalars['String'];
  warning?: Maybe<Scalars['String']>;
};


export type HomePageCategoryShowsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for HomePageCategory. */
export type HomePageCategoryConnection = {
  __typename?: 'HomePageCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HomePageCategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<HomePageCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type HomePageCategoryEdge = {
  __typename?: 'HomePageCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<HomePageCategory>;
};


export type KitsuResult = {
  __typename?: 'KitsuResult';
  canonicalTitle: Scalars['String'];
  nsfw: Scalars['Boolean'];
  platforms: Array<Platform>;
  posterImage: Poster;
  slug: Scalars['String'];
  status: Scalars['String'];
  titles: Scalars['Map'];
  year?: Maybe<Scalars['Int']>;
};


export type KitsuResultPlatformsArgs = {
  focusOn?: Maybe<Scalars['String']>;
  regionLocked?: Maybe<Scalars['RegionLocked']>;
};

export enum Layout {
  Expanded = 'EXPANDED',
  Simple = 'SIMPLE'
}

export type Link = {
  __typename?: 'Link';
  color?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  urlType: Scalars['String'];
  value: Scalars['String'];
};


export type Mutation = {
  __typename?: 'Mutation';
  addToMainQueue?: Maybe<Show>;
  removeFromMainQueue?: Maybe<Show>;
  /** An example field added by the generator */
  testField: Scalars['String'];
};


export type MutationAddToMainQueueArgs = {
  slug: Scalars['String'];
};


export type MutationRemoveFromMainQueueArgs = {
  slug: Scalars['String'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  totalCount: Scalars['Int'];
};

export type Platform = {
  __typename?: 'Platform';
  activeShows: ShowConnection;
  activeShowsCount: Scalars['Int'];
  airingNow: ShowConnection;
  anyAiringNow: Scalars['Boolean'];
  anyComingSoon: Scalars['Boolean'];
  availableInMyCountry: Scalars['Boolean'];
  blocked: Scalars['Boolean'];
  colour: Scalars['String'];
  comingSoon: ShowConnection;
  countries?: Maybe<Array<Scalars['Country']>>;
  global: Scalars['Boolean'];
  icon: Scalars['String'];
  image: Scalars['String'];
  name: Scalars['String'];
  otherShows: ShowConnection;
  shows: ShowConnection;
  showsCount: Scalars['Int'];
  title: Scalars['String'];
  url: Scalars['String'];
};


export type PlatformActiveShowsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PlatformAiringNowArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PlatformComingSoonArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PlatformOtherShowsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PlatformShowsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Platform. */
export type PlatformConnection = {
  __typename?: 'PlatformConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PlatformEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Platform>>>;
  pageInfo: PaginationInfo;
};

/** An edge in a connection. */
export type PlatformEdge = {
  __typename?: 'PlatformEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Platform>;
};

export type Poster = {
  __typename?: 'Poster';
  large?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  original: Scalars['String'];
  small?: Maybe<Scalars['String']>;
  tiny?: Maybe<Scalars['String']>;
};


export type Root = {
  __typename?: 'Root';
  browseAll: ShowConnection;
  countryTimezone: CountryTimezone;
  homePageCategories: HomePageCategoryConnection;
  homePageCategory?: Maybe<HomePageCategory>;
  homePageCategoryShows: ShowConnection;
  kitsuSearch: Array<KitsuResult>;
  nextAiringEpisode?: Maybe<AiringSchedule>;
  platform?: Maybe<Platform>;
  search: ShowConnection;
  show?: Maybe<Show>;
  showPlatforms: Array<Platform>;
  showSeasonYears: Array<Scalars['Int']>;
  showSeasons: Array<Season>;
  showTags: TagConnection;
  showTypes: Array<Scalars['String']>;
  shows: ShowConnection;
  streamableShows: ShowConnection;
  topPlatforms: PlatformConnection;
  trending: ShowConnection;
};


export type RootBrowseAllArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RootHomePageCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RootHomePageCategoryArgs = {
  slug: Scalars['String'];
};


export type RootHomePageCategoryShowsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  platforms?: Maybe<Array<Scalars['String']>>;
  searchTerm?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  showTypes?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
  tags?: Maybe<Array<Scalars['TagFilter']>>;
  year?: Maybe<Scalars['Int']>;
};


export type RootKitsuSearchArgs = {
  text: Scalars['String'];
};


export type RootNextAiringEpisodeArgs = {
  slug: Scalars['String'];
};


export type RootPlatformArgs = {
  name: Scalars['String'];
};


export type RootSearchArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  query: Scalars['String'];
  tags?: Maybe<Array<Scalars['TagFilter']>>;
};


export type RootShowArgs = {
  slug: Scalars['String'];
};


export type RootShowPlatformsArgs = {
  regionLocked?: Maybe<Scalars['RegionLocked']>;
};


export type RootShowTagsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RootShowsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  last?: Maybe<Scalars['Int']>;
  slugs: Array<Scalars['String']>;
};


export type RootStreamableShowsArgs = {
  after?: Maybe<Scalars['String']>;
  airing?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  direction?: Maybe<Array<Scalars['Boolean']>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  regionLocked?: Maybe<Scalars['Boolean']>;
  sortFilters?: Maybe<Array<Filter>>;
};


export type RootTopPlatformsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  regionLocked?: Maybe<Scalars['Boolean']>;
};


export type RootTrendingArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['TagFilter']>>;
};

export type Season = {
  __typename?: 'Season';
  value: Scalars['String'];
};

export type Show = {
  __typename?: 'Show';
  addedToMainQueue?: Maybe<Scalars['Boolean']>;
  ageRating: AgeRating;
  airingAt?: Maybe<Scalars['ISO8601DateTime']>;
  bannerUrl: Scalars['String'];
  description: Scalars['String'];
  dislikes: Scalars['Int'];
  endedOn?: Maybe<Scalars['ISO8601DateTime']>;
  episodesCount: Scalars['Int'];
  friendlyStatus?: Maybe<Scalars['String']>;
  likes: Scalars['Int'];
  links: Array<Link>;
  loves: Scalars['Int'];
  nextEpisode?: Maybe<Scalars['Int']>;
  nsfw: Scalars['Boolean'];
  otherLinks: Array<Link>;
  platforms: Array<Platform>;
  popularity: Scalars['Int'];
  popularityPercentage: Scalars['Int'];
  poster: Poster;
  posterUrl: Scalars['String'];
  rank?: Maybe<Scalars['Int']>;
  relatedShows: Array<Show>;
  relativePopularity: Scalars['Int'];
  showCategory?: Maybe<Scalars['String']>;
  showType: Scalars['String'];
  slug: Scalars['String'];
  startsOn?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<AiringStatus>;
  tags: Array<Tag>;
  title: Scalars['String'];
  titleRecord: Title;
  titles: Scalars['Map'];
  year?: Maybe<Scalars['Int']>;
  youtubeTrailerUrl?: Maybe<Scalars['String']>;
};


export type ShowLinksArgs = {
  regionLocked?: Maybe<Scalars['RegionLocked']>;
};


export type ShowPlatformsArgs = {
  focusOn?: Maybe<Scalars['String']>;
  regionLocked?: Maybe<Scalars['RegionLocked']>;
};


export type ShowPosterUrlArgs = {
  dimensions?: Maybe<Scalars['Dimensions']>;
};

/** The connection type for Show. */
export type ShowConnection = {
  __typename?: 'ShowConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShowEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Show>>>;
  pageInfo: PaginationInfo;
};

/** An edge in a connection. */
export type ShowEdge = {
  __typename?: 'ShowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Show>;
};

export type Tag = {
  __typename?: 'Tag';
  refId?: Maybe<Scalars['String']>;
  refUrl?: Maybe<Scalars['String']>;
  tagType: Scalars['String'];
  value: Scalars['String'];
};

/** The connection type for Tag. */
export type TagConnection = {
  __typename?: 'TagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TagEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Tag>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TagEdge = {
  __typename?: 'TagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Tag>;
};


export type Title = {
  __typename?: 'Title';
  en?: Maybe<Scalars['String']>;
  jp?: Maybe<Scalars['String']>;
};

export type GetTagsForSearchQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsForSearchQuery = (
  { __typename?: 'Root' }
  & { showTags: (
    { __typename?: 'TagConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'TagEdge' }
      & { node?: Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'value'>
      )> }
    )>>> }
  ) }
);

export type GetCategoryBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
}>;


export type GetCategoryBySlugQuery = (
  { __typename?: 'Root' }
  & { homePageCategory?: Maybe<(
    { __typename?: 'HomePageCategory' }
    & Pick<HomePageCategory, 'key' | 'title' | 'description' | 'layout' | 'canFetchMore' | 'featuredProps' | 'warning'>
    & { shows: (
      { __typename?: 'ShowConnection' }
      & { pageInfo: (
        { __typename?: 'PaginationInfo' }
        & Pick<PaginationInfo, 'hasNextPage' | 'totalCount'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'ShowEdge' }
        & Pick<ShowEdge, 'cursor'>
        & { node?: Maybe<(
          { __typename?: 'Show' }
          & Pick<Show, 'bannerUrl' | 'description' | 'dislikes' | 'endedOn' | 'episodesCount' | 'likes' | 'loves' | 'nsfw' | 'popularity' | 'posterUrl' | 'showType' | 'slug' | 'startsOn' | 'status' | 'friendlyStatus' | 'title' | 'year' | 'airingAt' | 'nextEpisode'>
          & { ageRating: (
            { __typename?: 'AgeRating' }
            & Pick<AgeRating, 'rating' | 'guide'>
          ), platforms: Array<(
            { __typename?: 'Platform' }
            & Pick<Platform, 'title' | 'name'>
          )> }
        )> }
      )>>> }
    ) }
  )> }
);

export type GetHomeQueryVariables = Exact<{
  top?: Maybe<Scalars['Int']>;
  showsCount?: Maybe<Scalars['Int']>;
}>;


export type GetHomeQuery = (
  { __typename?: 'Root' }
  & { topPlatforms: (
    { __typename?: 'PlatformConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'PlatformEdge' }
      & Pick<PlatformEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Platform' }
        & Pick<Platform, 'name' | 'title' | 'colour' | 'image' | 'icon' | 'url' | 'activeShowsCount'>
        & { activeShows: (
          { __typename?: 'ShowConnection' }
          & { pageInfo: (
            { __typename?: 'PaginationInfo' }
            & Pick<PaginationInfo, 'totalCount'>
          ), edges?: Maybe<Array<Maybe<(
            { __typename?: 'ShowEdge' }
            & { node?: Maybe<(
              { __typename?: 'Show' }
              & Pick<Show, 'bannerUrl' | 'description' | 'dislikes' | 'endedOn' | 'episodesCount' | 'likes' | 'loves' | 'nsfw' | 'popularity' | 'posterUrl' | 'showType' | 'slug' | 'startsOn' | 'status' | 'friendlyStatus' | 'title'>
              & { ageRating: (
                { __typename?: 'AgeRating' }
                & Pick<AgeRating, 'rating' | 'guide'>
              ) }
            )> }
          )>>> }
        ), shows: (
          { __typename?: 'ShowConnection' }
          & { pageInfo: (
            { __typename?: 'PaginationInfo' }
            & Pick<PaginationInfo, 'totalCount'>
          ) }
        ) }
      )> }
    )>>> }
  ) }
);

export type GetStreamingAiringShowsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetStreamingAiringShowsQuery = (
  { __typename?: 'Root' }
  & { countryTimezone: (
    { __typename?: 'CountryTimezone' }
    & Pick<CountryTimezone, 'country'>
  ), streamableShows: (
    { __typename?: 'ShowConnection' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'hasNextPage' | 'totalCount'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ShowEdge' }
      & { node?: Maybe<(
        { __typename?: 'Show' }
        & Pick<Show, 'bannerUrl' | 'description' | 'dislikes' | 'endedOn' | 'episodesCount' | 'likes' | 'loves' | 'nsfw' | 'popularity' | 'posterUrl' | 'showType' | 'slug' | 'startsOn' | 'status' | 'friendlyStatus' | 'title' | 'year' | 'airingAt'>
        & { ageRating: (
          { __typename?: 'AgeRating' }
          & Pick<AgeRating, 'rating' | 'guide'>
        ), platforms: Array<(
          { __typename?: 'Platform' }
          & Pick<Platform, 'title' | 'name'>
        )> }
      )> }
    )>>> }
  ) }
);

export type GetHomePageCategoriesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetHomePageCategoriesQuery = (
  { __typename?: 'Root' }
  & { homePageCategories: (
    { __typename?: 'HomePageCategoryConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasPreviousPage' | 'hasNextPage' | 'startCursor' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'HomePageCategoryEdge' }
      & Pick<HomePageCategoryEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'HomePageCategory' }
        & Pick<HomePageCategory, 'key' | 'title' | 'description' | 'layout' | 'canFetchMore' | 'featuredProps'>
      )> }
    )>>> }
  ) }
);

export type GetManyShowsBySlugQueryVariables = Exact<{
  slugs: Array<Scalars['String']> | Scalars['String'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetManyShowsBySlugQuery = (
  { __typename?: 'Root' }
  & { shows: (
    { __typename?: 'ShowConnection' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'totalCount' | 'hasNextPage' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ShowEdge' }
      & Pick<ShowEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Show' }
        & Pick<Show, 'bannerUrl' | 'description' | 'dislikes' | 'endedOn' | 'episodesCount' | 'likes' | 'loves' | 'nsfw' | 'popularity' | 'posterUrl' | 'showType' | 'slug' | 'startsOn' | 'status' | 'friendlyStatus' | 'title' | 'year' | 'airingAt' | 'nextEpisode'>
        & { ageRating: (
          { __typename?: 'AgeRating' }
          & Pick<AgeRating, 'rating' | 'guide'>
        ), platforms: Array<(
          { __typename?: 'Platform' }
          & Pick<Platform, 'title' | 'name'>
        )> }
      )> }
    )>>> }
  ) }
);

export type GetPlatformsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlatformsQuery = (
  { __typename?: 'Root' }
  & { topPlatforms: (
    { __typename?: 'PlatformConnection' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'totalCount'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'PlatformEdge' }
      & { node?: Maybe<(
        { __typename?: 'Platform' }
        & Pick<Platform, 'colour' | 'icon' | 'image' | 'name' | 'title' | 'url' | 'activeShowsCount' | 'showsCount' | 'global' | 'countries'>
      )> }
    )>>> }
  ) }
);

export type GetTrendingShowsQueryVariables = Exact<{
  tags?: Maybe<Array<Scalars['TagFilter']> | Scalars['TagFilter']>;
}>;


export type GetTrendingShowsQuery = (
  { __typename?: 'Root' }
  & { trending: (
    { __typename?: 'ShowConnection' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ShowEdge' }
      & Pick<ShowEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Show' }
        & Pick<Show, 'bannerUrl' | 'description' | 'dislikes' | 'endedOn' | 'episodesCount' | 'likes' | 'loves' | 'nsfw' | 'popularity' | 'posterUrl' | 'showType' | 'slug' | 'startsOn' | 'status' | 'friendlyStatus' | 'title'>
        & { ageRating: (
          { __typename?: 'AgeRating' }
          & Pick<AgeRating, 'rating' | 'guide'>
        ), platforms: Array<(
          { __typename?: 'Platform' }
          & Pick<Platform, 'title' | 'name'>
        )> }
      )> }
    )>>> }
  ) }
);

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
  tags?: Maybe<Array<Scalars['TagFilter']> | Scalars['TagFilter']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type SearchQuery = (
  { __typename?: 'Root' }
  & { search: (
    { __typename?: 'ShowConnection' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ShowEdge' }
      & Pick<ShowEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Show' }
        & Pick<Show, 'bannerUrl' | 'description' | 'dislikes' | 'endedOn' | 'episodesCount' | 'likes' | 'loves' | 'nsfw' | 'popularity' | 'posterUrl' | 'showType' | 'slug' | 'startsOn' | 'status' | 'friendlyStatus' | 'title' | 'year'>
        & { ageRating: (
          { __typename?: 'AgeRating' }
          & Pick<AgeRating, 'rating' | 'guide'>
        ), platforms: Array<(
          { __typename?: 'Platform' }
          & Pick<Platform, 'title' | 'name'>
        )> }
      )> }
    )>>> }
  ) }
);

export type PlatformFragmentFragment = (
  { __typename?: 'Platform' }
  & Pick<Platform, 'colour' | 'icon' | 'image' | 'name' | 'title' | 'url'>
  & { activeShows: (
    { __typename?: 'ShowConnection' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ShowEdge' }
      & Pick<ShowEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Show' }
        & PartialShowFragmentFragment
      )> }
    )>>> }
  ), shows: (
    { __typename?: 'ShowConnection' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ShowEdge' }
      & Pick<ShowEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Show' }
        & PartialShowFragmentFragment
      )> }
    )>>> }
  ) }
);

export type PartialShowFragmentFragment = (
  { __typename?: 'Show' }
  & Pick<Show, 'description' | 'endedOn' | 'episodesCount' | 'nsfw' | 'popularity' | 'posterUrl' | 'showType' | 'slug' | 'startsOn' | 'status' | 'friendlyStatus' | 'title'>
  & { ageRating: (
    { __typename?: 'AgeRating' }
    & Pick<AgeRating, 'rating' | 'guide'>
  ) }
);

export type ShowFragmentFragment = (
  { __typename?: 'Show' }
  & Pick<Show, 'bannerUrl' | 'dislikes' | 'likes' | 'loves' | 'popularityPercentage'>
  & { links: Array<(
    { __typename?: 'Link' }
    & Pick<Link, 'value' | 'urlType'>
    & { platform?: Maybe<(
      { __typename?: 'Platform' }
      & PlatformFragmentFragment
    )> }
  )>, platforms: Array<(
    { __typename?: 'Platform' }
    & PlatformFragmentFragment
  )>, relatedShows: Array<(
    { __typename?: 'Show' }
    & Pick<Show, 'posterUrl' | 'slug' | 'title'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'value'>
  )>, titleRecord: (
    { __typename?: 'Title' }
    & Pick<Title, 'en' | 'jp'>
  ) }
  & PartialShowFragmentFragment
);

export type GetShowsForCategoryQueryVariables = Exact<{
  slug: Scalars['String'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetShowsForCategoryQuery = (
  { __typename?: 'Root' }
  & { homePageCategoryShows: (
    { __typename?: 'ShowConnection' }
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'endCursor' | 'hasNextPage' | 'totalCount'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ShowEdge' }
      & Pick<ShowEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Show' }
        & Pick<Show, 'bannerUrl' | 'description' | 'dislikes' | 'endedOn' | 'episodesCount' | 'likes' | 'loves' | 'nsfw' | 'popularity' | 'posterUrl' | 'showType' | 'slug' | 'startsOn' | 'status' | 'friendlyStatus' | 'title' | 'year' | 'airingAt' | 'nextEpisode'>
        & { ageRating: (
          { __typename?: 'AgeRating' }
          & Pick<AgeRating, 'rating' | 'guide'>
        ), platforms: Array<(
          { __typename?: 'Platform' }
          & Pick<Platform, 'title' | 'name'>
        )> }
      )> }
    )>>> }
  ) }
);

export type GetPlatformAiringNowQueryVariables = Exact<{
  name: Scalars['String'];
  top?: Maybe<Scalars['Int']>;
}>;


export type GetPlatformAiringNowQuery = (
  { __typename?: 'Root' }
  & { platform?: Maybe<(
    { __typename?: 'Platform' }
    & { airingNow: (
      { __typename?: 'ShowConnection' }
      & { pageInfo: (
        { __typename?: 'PaginationInfo' }
        & Pick<PaginationInfo, 'totalCount' | 'hasNextPage'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'ShowEdge' }
        & { node?: Maybe<(
          { __typename?: 'Show' }
          & Pick<Show, 'slug' | 'friendlyStatus' | 'status' | 'rank' | 'startsOn' | 'endedOn' | 'bannerUrl' | 'posterUrl' | 'episodesCount' | 'popularity' | 'title' | 'year'>
          & { ageRating: (
            { __typename?: 'AgeRating' }
            & Pick<AgeRating, 'guide' | 'rating'>
          ), tags: Array<(
            { __typename?: 'Tag' }
            & Pick<Tag, 'refId' | 'refUrl' | 'tagType' | 'value'>
          )>, titleRecord: (
            { __typename?: 'Title' }
            & Pick<Title, 'en' | 'jp'>
          ) }
        )> }
      )>>> }
    ) }
  )> }
);

export type PlaftformShowFragment = (
  { __typename?: 'Show' }
  & Pick<Show, 'slug' | 'friendlyStatus' | 'status' | 'rank' | 'startsOn' | 'endedOn' | 'bannerUrl' | 'posterUrl' | 'episodesCount' | 'popularity' | 'title' | 'year'>
  & { ageRating: (
    { __typename?: 'AgeRating' }
    & Pick<AgeRating, 'guide' | 'rating'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'refId' | 'refUrl' | 'tagType' | 'value'>
  )>, titleRecord: (
    { __typename?: 'Title' }
    & Pick<Title, 'en' | 'jp'>
  ) }
);

export type GetPlatformByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetPlatformByNameQuery = (
  { __typename?: 'Root' }
  & { platform?: Maybe<(
    { __typename?: 'Platform' }
    & Pick<Platform, 'anyAiringNow' | 'anyComingSoon' | 'showsCount' | 'colour' | 'icon' | 'image' | 'name' | 'url' | 'title' | 'global' | 'countries' | 'availableInMyCountry' | 'blocked'>
  )> }
);

export type GetPlatformComingSoonQueryVariables = Exact<{
  name: Scalars['String'];
  top?: Maybe<Scalars['Int']>;
}>;


export type GetPlatformComingSoonQuery = (
  { __typename?: 'Root' }
  & { platform?: Maybe<(
    { __typename?: 'Platform' }
    & { comingSoon: (
      { __typename?: 'ShowConnection' }
      & { pageInfo: (
        { __typename?: 'PaginationInfo' }
        & Pick<PaginationInfo, 'totalCount' | 'hasNextPage'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'ShowEdge' }
        & { node?: Maybe<(
          { __typename?: 'Show' }
          & Pick<Show, 'slug' | 'friendlyStatus' | 'status' | 'rank' | 'startsOn' | 'endedOn' | 'bannerUrl' | 'posterUrl' | 'episodesCount' | 'popularity' | 'title' | 'year'>
          & { ageRating: (
            { __typename?: 'AgeRating' }
            & Pick<AgeRating, 'guide' | 'rating'>
          ), tags: Array<(
            { __typename?: 'Tag' }
            & Pick<Tag, 'refId' | 'refUrl' | 'tagType' | 'value'>
          )>, titleRecord: (
            { __typename?: 'Title' }
            & Pick<Title, 'en' | 'jp'>
          ) }
        )> }
      )>>> }
    ) }
  )> }
);

export type GetPlatformOtherShowsQueryVariables = Exact<{
  name: Scalars['String'];
  top?: Maybe<Scalars['Int']>;
}>;


export type GetPlatformOtherShowsQuery = (
  { __typename?: 'Root' }
  & { platform?: Maybe<(
    { __typename?: 'Platform' }
    & { otherShows: (
      { __typename?: 'ShowConnection' }
      & { pageInfo: (
        { __typename?: 'PaginationInfo' }
        & Pick<PaginationInfo, 'totalCount' | 'hasNextPage'>
      ), edges?: Maybe<Array<Maybe<(
        { __typename?: 'ShowEdge' }
        & { node?: Maybe<(
          { __typename?: 'Show' }
          & Pick<Show, 'slug' | 'friendlyStatus' | 'status' | 'rank' | 'startsOn' | 'endedOn' | 'bannerUrl' | 'posterUrl' | 'episodesCount' | 'popularity' | 'title' | 'year'>
          & { ageRating: (
            { __typename?: 'AgeRating' }
            & Pick<AgeRating, 'guide' | 'rating'>
          ), tags: Array<(
            { __typename?: 'Tag' }
            & Pick<Tag, 'refId' | 'refUrl' | 'tagType' | 'value'>
          )>, titleRecord: (
            { __typename?: 'Title' }
            & Pick<Title, 'en' | 'jp'>
          ) }
        )> }
      )>>> }
    ) }
  )> }
);

export type AddToWatchListMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type AddToWatchListMutation = (
  { __typename?: 'Mutation' }
  & { addToMainQueue?: Maybe<(
    { __typename?: 'Show' }
    & Pick<Show, 'slug' | 'addedToMainQueue'>
  )> }
);

export type RemoveFromWatchListMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type RemoveFromWatchListMutation = (
  { __typename?: 'Mutation' }
  & { removeFromMainQueue?: Maybe<(
    { __typename?: 'Show' }
    & Pick<Show, 'slug' | 'addedToMainQueue'>
  )> }
);

export type GetShowBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetShowBySlugQuery = (
  { __typename?: 'Root' }
  & { nextAiringEpisode?: Maybe<(
    { __typename?: 'AiringSchedule' }
    & Pick<AiringSchedule, 'airingAt' | 'timeUntilAiring' | 'episodeNumber'>
  )>, show?: Maybe<(
    { __typename?: 'Show' }
    & Pick<Show, 'addedToMainQueue' | 'airingAt' | 'bannerUrl' | 'description' | 'dislikes' | 'endedOn' | 'episodesCount' | 'friendlyStatus' | 'likes' | 'loves' | 'nsfw' | 'popularity' | 'popularityPercentage' | 'posterUrl' | 'relativePopularity' | 'showType' | 'showCategory' | 'slug' | 'startsOn' | 'status' | 'title' | 'year' | 'youtubeTrailerUrl'>
    & { ageRating: (
      { __typename?: 'AgeRating' }
      & Pick<AgeRating, 'rating' | 'guide'>
    ), links: Array<(
      { __typename?: 'Link' }
      & Pick<Link, 'value' | 'urlType'>
      & { platform?: Maybe<(
        { __typename?: 'Platform' }
        & Pick<Platform, 'icon' | 'title' | 'name' | 'colour' | 'image' | 'url'>
      )> }
    )>, otherLinks: Array<(
      { __typename?: 'Link' }
      & Pick<Link, 'value' | 'urlType' | 'color'>
    )>, relatedShows: Array<(
      { __typename?: 'Show' }
      & Pick<Show, 'posterUrl' | 'slug' | 'title' | 'status' | 'friendlyStatus' | 'year' | 'showType'>
      & { platforms: Array<(
        { __typename?: 'Platform' }
        & Pick<Platform, 'icon' | 'name'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'value' | 'tagType' | 'refId' | 'refUrl'>
      )> }
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'value'>
    )>, titleRecord: (
      { __typename?: 'Title' }
      & Pick<Title, 'en' | 'jp'>
    ) }
  )> }
);

export type NextAiringEpisodeQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type NextAiringEpisodeQuery = (
  { __typename?: 'Root' }
  & { nextAiringEpisode?: Maybe<(
    { __typename?: 'AiringSchedule' }
    & Pick<AiringSchedule, 'airingAt' | 'timeUntilAiring' | 'episodeNumber'>
  )> }
);

export const PartialShowFragmentFragmentDoc = gql`
    fragment PartialShowFragment on Show {
  ageRating {
    rating
    guide
  }
  description
  endedOn
  episodesCount
  nsfw
  popularity
  posterUrl
  showType
  slug
  startsOn
  status
  friendlyStatus
  title
}
    `;
export const PlatformFragmentFragmentDoc = gql`
    fragment PlatformFragment on Platform {
  activeShows {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...PartialShowFragment
      }
    }
  }
  colour
  icon
  image
  name
  shows {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...PartialShowFragment
      }
    }
  }
  title
  url
}
    ${PartialShowFragmentFragmentDoc}`;
export const ShowFragmentFragmentDoc = gql`
    fragment ShowFragment on Show {
  ...PartialShowFragment
  bannerUrl
  dislikes
  likes
  links {
    value
    urlType
    platform {
      ...PlatformFragment
    }
  }
  loves
  platforms {
    ...PlatformFragment
  }
  popularityPercentage
  relatedShows {
    posterUrl
    slug
    title
  }
  tags {
    value
  }
  titleRecord {
    en
    jp
  }
}
    ${PartialShowFragmentFragmentDoc}
${PlatformFragmentFragmentDoc}`;
export const PlaftformShowFragmentDoc = gql`
    fragment PlaftformShow on Show {
  ageRating {
    guide
    rating
  }
  slug
  friendlyStatus
  status
  rank
  startsOn
  endedOn
  bannerUrl
  posterUrl
  episodesCount
  popularity
  tags {
    refId
    refUrl
    tagType
    value
  }
  title
  titleRecord {
    en
    jp
  }
  year
}
    `;
export const GetTagsForSearchDocument = gql`
    query GetTagsForSearch {
  showTags {
    pageInfo {
      hasNextPage
    }
    edges {
      node {
        value
      }
    }
  }
}
    `;

/**
 * __useGetTagsForSearchQuery__
 *
 * To run a query within a React component, call `useGetTagsForSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsForSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsForSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsForSearchQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsForSearchQuery, GetTagsForSearchQueryVariables>) {
        return Apollo.useQuery<GetTagsForSearchQuery, GetTagsForSearchQueryVariables>(GetTagsForSearchDocument, baseOptions);
      }
export function useGetTagsForSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsForSearchQuery, GetTagsForSearchQueryVariables>) {
          return Apollo.useLazyQuery<GetTagsForSearchQuery, GetTagsForSearchQueryVariables>(GetTagsForSearchDocument, baseOptions);
        }
export type GetTagsForSearchQueryHookResult = ReturnType<typeof useGetTagsForSearchQuery>;
export type GetTagsForSearchLazyQueryHookResult = ReturnType<typeof useGetTagsForSearchLazyQuery>;
export type GetTagsForSearchQueryResult = Apollo.QueryResult<GetTagsForSearchQuery, GetTagsForSearchQueryVariables>;
export const GetCategoryBySlugDocument = gql`
    query GetCategoryBySlug($slug: String!, $first: Int) {
  homePageCategory(slug: $slug) {
    key
    title
    description
    layout
    canFetchMore
    featuredProps
    shows(first: $first) {
      pageInfo {
        hasNextPage
        totalCount
      }
      edges {
        cursor
        node {
          ageRating {
            rating
            guide
          }
          platforms {
            title
            name
          }
          bannerUrl
          description
          dislikes
          endedOn
          episodesCount
          likes
          loves
          nsfw
          popularity
          posterUrl
          showType
          slug
          startsOn
          status
          friendlyStatus
          title
          year
          airingAt
          nextEpisode
        }
      }
    }
    warning
  }
}
    `;

/**
 * __useGetCategoryBySlugQuery__
 *
 * To run a query within a React component, call `useGetCategoryBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetCategoryBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryBySlugQuery, GetCategoryBySlugQueryVariables>) {
        return Apollo.useQuery<GetCategoryBySlugQuery, GetCategoryBySlugQueryVariables>(GetCategoryBySlugDocument, baseOptions);
      }
export function useGetCategoryBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryBySlugQuery, GetCategoryBySlugQueryVariables>) {
          return Apollo.useLazyQuery<GetCategoryBySlugQuery, GetCategoryBySlugQueryVariables>(GetCategoryBySlugDocument, baseOptions);
        }
export type GetCategoryBySlugQueryHookResult = ReturnType<typeof useGetCategoryBySlugQuery>;
export type GetCategoryBySlugLazyQueryHookResult = ReturnType<typeof useGetCategoryBySlugLazyQuery>;
export type GetCategoryBySlugQueryResult = Apollo.QueryResult<GetCategoryBySlugQuery, GetCategoryBySlugQueryVariables>;
export const GetHomeDocument = gql`
    query GetHome($top: Int, $showsCount: Int) {
  topPlatforms(first: $top) {
    edges {
      node {
        name
        title
        colour
        image
        icon
        url
        activeShowsCount
        activeShows(first: $showsCount) {
          pageInfo {
            totalCount
          }
          edges {
            node {
              ageRating {
                rating
                guide
              }
              bannerUrl
              description
              dislikes
              endedOn
              episodesCount
              likes
              loves
              nsfw
              popularity
              posterUrl
              showType
              slug
              startsOn
              status
              friendlyStatus
              title
            }
          }
        }
        shows {
          pageInfo {
            totalCount
          }
        }
      }
      cursor
    }
  }
}
    `;

/**
 * __useGetHomeQuery__
 *
 * To run a query within a React component, call `useGetHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeQuery({
 *   variables: {
 *      top: // value for 'top'
 *      showsCount: // value for 'showsCount'
 *   },
 * });
 */
export function useGetHomeQuery(baseOptions?: Apollo.QueryHookOptions<GetHomeQuery, GetHomeQueryVariables>) {
        return Apollo.useQuery<GetHomeQuery, GetHomeQueryVariables>(GetHomeDocument, baseOptions);
      }
export function useGetHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomeQuery, GetHomeQueryVariables>) {
          return Apollo.useLazyQuery<GetHomeQuery, GetHomeQueryVariables>(GetHomeDocument, baseOptions);
        }
export type GetHomeQueryHookResult = ReturnType<typeof useGetHomeQuery>;
export type GetHomeLazyQueryHookResult = ReturnType<typeof useGetHomeLazyQuery>;
export type GetHomeQueryResult = Apollo.QueryResult<GetHomeQuery, GetHomeQueryVariables>;
export const GetStreamingAiringShowsDocument = gql`
    query GetStreamingAiringShows($limit: Int) {
  countryTimezone {
    country
  }
  streamableShows(
    limit: $limit
    sortFilters: [AIRING_AT]
    airing: true
    regionLocked: true
  ) {
    pageInfo {
      hasNextPage
      totalCount
    }
    edges {
      node {
        ageRating {
          rating
          guide
        }
        platforms {
          title
          name
        }
        bannerUrl
        description
        dislikes
        endedOn
        episodesCount
        likes
        loves
        nsfw
        popularity
        posterUrl
        showType
        slug
        startsOn
        status
        friendlyStatus
        title
        year
        airingAt
      }
    }
  }
}
    `;

/**
 * __useGetStreamingAiringShowsQuery__
 *
 * To run a query within a React component, call `useGetStreamingAiringShowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamingAiringShowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamingAiringShowsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetStreamingAiringShowsQuery(baseOptions?: Apollo.QueryHookOptions<GetStreamingAiringShowsQuery, GetStreamingAiringShowsQueryVariables>) {
        return Apollo.useQuery<GetStreamingAiringShowsQuery, GetStreamingAiringShowsQueryVariables>(GetStreamingAiringShowsDocument, baseOptions);
      }
export function useGetStreamingAiringShowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreamingAiringShowsQuery, GetStreamingAiringShowsQueryVariables>) {
          return Apollo.useLazyQuery<GetStreamingAiringShowsQuery, GetStreamingAiringShowsQueryVariables>(GetStreamingAiringShowsDocument, baseOptions);
        }
export type GetStreamingAiringShowsQueryHookResult = ReturnType<typeof useGetStreamingAiringShowsQuery>;
export type GetStreamingAiringShowsLazyQueryHookResult = ReturnType<typeof useGetStreamingAiringShowsLazyQuery>;
export type GetStreamingAiringShowsQueryResult = Apollo.QueryResult<GetStreamingAiringShowsQuery, GetStreamingAiringShowsQueryVariables>;
export const GetHomePageCategoriesDocument = gql`
    query GetHomePageCategories($first: Int, $after: String) {
  homePageCategories(first: $first, after: $after) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        key
        title
        description
        layout
        canFetchMore
        featuredProps
      }
    }
  }
}
    `;

/**
 * __useGetHomePageCategoriesQuery__
 *
 * To run a query within a React component, call `useGetHomePageCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomePageCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomePageCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetHomePageCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetHomePageCategoriesQuery, GetHomePageCategoriesQueryVariables>) {
        return Apollo.useQuery<GetHomePageCategoriesQuery, GetHomePageCategoriesQueryVariables>(GetHomePageCategoriesDocument, baseOptions);
      }
export function useGetHomePageCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomePageCategoriesQuery, GetHomePageCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetHomePageCategoriesQuery, GetHomePageCategoriesQueryVariables>(GetHomePageCategoriesDocument, baseOptions);
        }
export type GetHomePageCategoriesQueryHookResult = ReturnType<typeof useGetHomePageCategoriesQuery>;
export type GetHomePageCategoriesLazyQueryHookResult = ReturnType<typeof useGetHomePageCategoriesLazyQuery>;
export type GetHomePageCategoriesQueryResult = Apollo.QueryResult<GetHomePageCategoriesQuery, GetHomePageCategoriesQueryVariables>;
export const GetManyShowsBySlugDocument = gql`
    query GetManyShowsBySlug($slugs: [String!]!, $first: Int!, $after: String) {
  shows(slugs: $slugs, first: $first, after: $after) {
    pageInfo {
      totalCount
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        ageRating {
          rating
          guide
        }
        platforms {
          title
          name
        }
        bannerUrl
        description
        dislikes
        endedOn
        episodesCount
        likes
        loves
        nsfw
        popularity
        posterUrl
        showType
        slug
        startsOn
        status
        friendlyStatus
        title
        year
        airingAt
        nextEpisode
      }
    }
  }
}
    `;

/**
 * __useGetManyShowsBySlugQuery__
 *
 * To run a query within a React component, call `useGetManyShowsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyShowsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyShowsBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetManyShowsBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetManyShowsBySlugQuery, GetManyShowsBySlugQueryVariables>) {
        return Apollo.useQuery<GetManyShowsBySlugQuery, GetManyShowsBySlugQueryVariables>(GetManyShowsBySlugDocument, baseOptions);
      }
export function useGetManyShowsBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyShowsBySlugQuery, GetManyShowsBySlugQueryVariables>) {
          return Apollo.useLazyQuery<GetManyShowsBySlugQuery, GetManyShowsBySlugQueryVariables>(GetManyShowsBySlugDocument, baseOptions);
        }
export type GetManyShowsBySlugQueryHookResult = ReturnType<typeof useGetManyShowsBySlugQuery>;
export type GetManyShowsBySlugLazyQueryHookResult = ReturnType<typeof useGetManyShowsBySlugLazyQuery>;
export type GetManyShowsBySlugQueryResult = Apollo.QueryResult<GetManyShowsBySlugQuery, GetManyShowsBySlugQueryVariables>;
export const GetPlatformsDocument = gql`
    query GetPlatforms {
  topPlatforms(regionLocked: true) {
    pageInfo {
      totalCount
    }
    edges {
      node {
        colour
        icon
        image
        name
        title
        url
        activeShowsCount
        showsCount
        global
        countries
      }
    }
  }
}
    `;

/**
 * __useGetPlatformsQuery__
 *
 * To run a query within a React component, call `useGetPlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlatformsQuery(baseOptions?: Apollo.QueryHookOptions<GetPlatformsQuery, GetPlatformsQueryVariables>) {
        return Apollo.useQuery<GetPlatformsQuery, GetPlatformsQueryVariables>(GetPlatformsDocument, baseOptions);
      }
export function useGetPlatformsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformsQuery, GetPlatformsQueryVariables>) {
          return Apollo.useLazyQuery<GetPlatformsQuery, GetPlatformsQueryVariables>(GetPlatformsDocument, baseOptions);
        }
export type GetPlatformsQueryHookResult = ReturnType<typeof useGetPlatformsQuery>;
export type GetPlatformsLazyQueryHookResult = ReturnType<typeof useGetPlatformsLazyQuery>;
export type GetPlatformsQueryResult = Apollo.QueryResult<GetPlatformsQuery, GetPlatformsQueryVariables>;
export const GetTrendingShowsDocument = gql`
    query GetTrendingShows($tags: [TagFilter!]) {
  trending(tags: $tags, first: 50) {
    pageInfo {
      hasNextPage
    }
    edges {
      cursor
      node {
        ageRating {
          rating
          guide
        }
        bannerUrl
        description
        dislikes
        endedOn
        episodesCount
        likes
        loves
        nsfw
        platforms {
          title
          name
        }
        popularity
        posterUrl
        showType
        slug
        startsOn
        status
        friendlyStatus
        title
      }
    }
  }
}
    `;

/**
 * __useGetTrendingShowsQuery__
 *
 * To run a query within a React component, call `useGetTrendingShowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrendingShowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrendingShowsQuery({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetTrendingShowsQuery(baseOptions?: Apollo.QueryHookOptions<GetTrendingShowsQuery, GetTrendingShowsQueryVariables>) {
        return Apollo.useQuery<GetTrendingShowsQuery, GetTrendingShowsQueryVariables>(GetTrendingShowsDocument, baseOptions);
      }
export function useGetTrendingShowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrendingShowsQuery, GetTrendingShowsQueryVariables>) {
          return Apollo.useLazyQuery<GetTrendingShowsQuery, GetTrendingShowsQueryVariables>(GetTrendingShowsDocument, baseOptions);
        }
export type GetTrendingShowsQueryHookResult = ReturnType<typeof useGetTrendingShowsQuery>;
export type GetTrendingShowsLazyQueryHookResult = ReturnType<typeof useGetTrendingShowsLazyQuery>;
export type GetTrendingShowsQueryResult = Apollo.QueryResult<GetTrendingShowsQuery, GetTrendingShowsQueryVariables>;
export const SearchDocument = gql`
    query Search($query: String!, $tags: [TagFilter!], $limit: Int = 10) {
  search(query: $query, tags: $tags, limit: $limit) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ageRating {
          rating
          guide
        }
        bannerUrl
        description
        dislikes
        endedOn
        episodesCount
        likes
        loves
        nsfw
        platforms {
          title
          name
        }
        popularity
        posterUrl
        showType
        slug
        startsOn
        status
        friendlyStatus
        title
        year
      }
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      tags: // value for 'tags'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const GetShowsForCategoryDocument = gql`
    query GetShowsForCategory($slug: String!, $first: Int!, $after: String) {
  homePageCategoryShows(slug: $slug, first: $first, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
      totalCount
    }
    edges {
      cursor
      node {
        ageRating {
          rating
          guide
        }
        platforms {
          title
          name
        }
        bannerUrl
        description
        dislikes
        endedOn
        episodesCount
        likes
        loves
        nsfw
        popularity
        posterUrl
        showType
        slug
        startsOn
        status
        friendlyStatus
        title
        year
        airingAt
        nextEpisode
      }
    }
  }
}
    `;

/**
 * __useGetShowsForCategoryQuery__
 *
 * To run a query within a React component, call `useGetShowsForCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowsForCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowsForCategoryQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetShowsForCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetShowsForCategoryQuery, GetShowsForCategoryQueryVariables>) {
        return Apollo.useQuery<GetShowsForCategoryQuery, GetShowsForCategoryQueryVariables>(GetShowsForCategoryDocument, baseOptions);
      }
export function useGetShowsForCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShowsForCategoryQuery, GetShowsForCategoryQueryVariables>) {
          return Apollo.useLazyQuery<GetShowsForCategoryQuery, GetShowsForCategoryQueryVariables>(GetShowsForCategoryDocument, baseOptions);
        }
export type GetShowsForCategoryQueryHookResult = ReturnType<typeof useGetShowsForCategoryQuery>;
export type GetShowsForCategoryLazyQueryHookResult = ReturnType<typeof useGetShowsForCategoryLazyQuery>;
export type GetShowsForCategoryQueryResult = Apollo.QueryResult<GetShowsForCategoryQuery, GetShowsForCategoryQueryVariables>;
export const GetPlatformAiringNowDocument = gql`
    query GetPlatformAiringNow($name: String!, $top: Int = 20) {
  platform(name: $name) {
    airingNow(first: $top) {
      pageInfo {
        totalCount
        hasNextPage
      }
      edges {
        node {
          ageRating {
            guide
            rating
          }
          slug
          friendlyStatus
          status
          rank
          startsOn
          endedOn
          bannerUrl
          posterUrl
          episodesCount
          popularity
          tags {
            refId
            refUrl
            tagType
            value
          }
          title
          titleRecord {
            en
            jp
          }
          year
        }
      }
    }
  }
}
    `;

/**
 * __useGetPlatformAiringNowQuery__
 *
 * To run a query within a React component, call `useGetPlatformAiringNowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformAiringNowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformAiringNowQuery({
 *   variables: {
 *      name: // value for 'name'
 *      top: // value for 'top'
 *   },
 * });
 */
export function useGetPlatformAiringNowQuery(baseOptions: Apollo.QueryHookOptions<GetPlatformAiringNowQuery, GetPlatformAiringNowQueryVariables>) {
        return Apollo.useQuery<GetPlatformAiringNowQuery, GetPlatformAiringNowQueryVariables>(GetPlatformAiringNowDocument, baseOptions);
      }
export function useGetPlatformAiringNowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformAiringNowQuery, GetPlatformAiringNowQueryVariables>) {
          return Apollo.useLazyQuery<GetPlatformAiringNowQuery, GetPlatformAiringNowQueryVariables>(GetPlatformAiringNowDocument, baseOptions);
        }
export type GetPlatformAiringNowQueryHookResult = ReturnType<typeof useGetPlatformAiringNowQuery>;
export type GetPlatformAiringNowLazyQueryHookResult = ReturnType<typeof useGetPlatformAiringNowLazyQuery>;
export type GetPlatformAiringNowQueryResult = Apollo.QueryResult<GetPlatformAiringNowQuery, GetPlatformAiringNowQueryVariables>;
export const GetPlatformByNameDocument = gql`
    query GetPlatformByName($name: String!) {
  platform(name: $name) {
    anyAiringNow
    anyComingSoon
    showsCount
    colour
    icon
    image
    name
    url
    title
    global
    countries
    availableInMyCountry
    blocked
  }
}
    `;

/**
 * __useGetPlatformByNameQuery__
 *
 * To run a query within a React component, call `useGetPlatformByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetPlatformByNameQuery(baseOptions: Apollo.QueryHookOptions<GetPlatformByNameQuery, GetPlatformByNameQueryVariables>) {
        return Apollo.useQuery<GetPlatformByNameQuery, GetPlatformByNameQueryVariables>(GetPlatformByNameDocument, baseOptions);
      }
export function useGetPlatformByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformByNameQuery, GetPlatformByNameQueryVariables>) {
          return Apollo.useLazyQuery<GetPlatformByNameQuery, GetPlatformByNameQueryVariables>(GetPlatformByNameDocument, baseOptions);
        }
export type GetPlatformByNameQueryHookResult = ReturnType<typeof useGetPlatformByNameQuery>;
export type GetPlatformByNameLazyQueryHookResult = ReturnType<typeof useGetPlatformByNameLazyQuery>;
export type GetPlatformByNameQueryResult = Apollo.QueryResult<GetPlatformByNameQuery, GetPlatformByNameQueryVariables>;
export const GetPlatformComingSoonDocument = gql`
    query GetPlatformComingSoon($name: String!, $top: Int = 20) {
  platform(name: $name) {
    comingSoon(first: $top) {
      pageInfo {
        totalCount
        hasNextPage
      }
      edges {
        node {
          ageRating {
            guide
            rating
          }
          slug
          friendlyStatus
          status
          rank
          startsOn
          endedOn
          bannerUrl
          posterUrl
          episodesCount
          popularity
          tags {
            refId
            refUrl
            tagType
            value
          }
          title
          titleRecord {
            en
            jp
          }
          year
        }
      }
    }
  }
}
    `;

/**
 * __useGetPlatformComingSoonQuery__
 *
 * To run a query within a React component, call `useGetPlatformComingSoonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformComingSoonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformComingSoonQuery({
 *   variables: {
 *      name: // value for 'name'
 *      top: // value for 'top'
 *   },
 * });
 */
export function useGetPlatformComingSoonQuery(baseOptions: Apollo.QueryHookOptions<GetPlatformComingSoonQuery, GetPlatformComingSoonQueryVariables>) {
        return Apollo.useQuery<GetPlatformComingSoonQuery, GetPlatformComingSoonQueryVariables>(GetPlatformComingSoonDocument, baseOptions);
      }
export function useGetPlatformComingSoonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformComingSoonQuery, GetPlatformComingSoonQueryVariables>) {
          return Apollo.useLazyQuery<GetPlatformComingSoonQuery, GetPlatformComingSoonQueryVariables>(GetPlatformComingSoonDocument, baseOptions);
        }
export type GetPlatformComingSoonQueryHookResult = ReturnType<typeof useGetPlatformComingSoonQuery>;
export type GetPlatformComingSoonLazyQueryHookResult = ReturnType<typeof useGetPlatformComingSoonLazyQuery>;
export type GetPlatformComingSoonQueryResult = Apollo.QueryResult<GetPlatformComingSoonQuery, GetPlatformComingSoonQueryVariables>;
export const GetPlatformOtherShowsDocument = gql`
    query GetPlatformOtherShows($name: String!, $top: Int = 20) {
  platform(name: $name) {
    otherShows(first: $top) {
      pageInfo {
        totalCount
        hasNextPage
      }
      edges {
        node {
          ageRating {
            guide
            rating
          }
          slug
          friendlyStatus
          status
          rank
          startsOn
          endedOn
          bannerUrl
          posterUrl
          episodesCount
          popularity
          tags {
            refId
            refUrl
            tagType
            value
          }
          title
          titleRecord {
            en
            jp
          }
          year
        }
      }
    }
  }
}
    `;

/**
 * __useGetPlatformOtherShowsQuery__
 *
 * To run a query within a React component, call `useGetPlatformOtherShowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformOtherShowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformOtherShowsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      top: // value for 'top'
 *   },
 * });
 */
export function useGetPlatformOtherShowsQuery(baseOptions: Apollo.QueryHookOptions<GetPlatformOtherShowsQuery, GetPlatformOtherShowsQueryVariables>) {
        return Apollo.useQuery<GetPlatformOtherShowsQuery, GetPlatformOtherShowsQueryVariables>(GetPlatformOtherShowsDocument, baseOptions);
      }
export function useGetPlatformOtherShowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformOtherShowsQuery, GetPlatformOtherShowsQueryVariables>) {
          return Apollo.useLazyQuery<GetPlatformOtherShowsQuery, GetPlatformOtherShowsQueryVariables>(GetPlatformOtherShowsDocument, baseOptions);
        }
export type GetPlatformOtherShowsQueryHookResult = ReturnType<typeof useGetPlatformOtherShowsQuery>;
export type GetPlatformOtherShowsLazyQueryHookResult = ReturnType<typeof useGetPlatformOtherShowsLazyQuery>;
export type GetPlatformOtherShowsQueryResult = Apollo.QueryResult<GetPlatformOtherShowsQuery, GetPlatformOtherShowsQueryVariables>;
export const AddToWatchListDocument = gql`
    mutation AddToWatchList($slug: String!) {
  addToMainQueue(slug: $slug) {
    slug
    addedToMainQueue
  }
}
    `;
export type AddToWatchListMutationFn = Apollo.MutationFunction<AddToWatchListMutation, AddToWatchListMutationVariables>;

/**
 * __useAddToWatchListMutation__
 *
 * To run a mutation, you first call `useAddToWatchListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWatchListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWatchListMutation, { data, loading, error }] = useAddToWatchListMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAddToWatchListMutation(baseOptions?: Apollo.MutationHookOptions<AddToWatchListMutation, AddToWatchListMutationVariables>) {
        return Apollo.useMutation<AddToWatchListMutation, AddToWatchListMutationVariables>(AddToWatchListDocument, baseOptions);
      }
export type AddToWatchListMutationHookResult = ReturnType<typeof useAddToWatchListMutation>;
export type AddToWatchListMutationResult = Apollo.MutationResult<AddToWatchListMutation>;
export type AddToWatchListMutationOptions = Apollo.BaseMutationOptions<AddToWatchListMutation, AddToWatchListMutationVariables>;
export const RemoveFromWatchListDocument = gql`
    mutation RemoveFromWatchList($slug: String!) {
  removeFromMainQueue(slug: $slug) {
    slug
    addedToMainQueue
  }
}
    `;
export type RemoveFromWatchListMutationFn = Apollo.MutationFunction<RemoveFromWatchListMutation, RemoveFromWatchListMutationVariables>;

/**
 * __useRemoveFromWatchListMutation__
 *
 * To run a mutation, you first call `useRemoveFromWatchListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromWatchListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromWatchListMutation, { data, loading, error }] = useRemoveFromWatchListMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRemoveFromWatchListMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromWatchListMutation, RemoveFromWatchListMutationVariables>) {
        return Apollo.useMutation<RemoveFromWatchListMutation, RemoveFromWatchListMutationVariables>(RemoveFromWatchListDocument, baseOptions);
      }
export type RemoveFromWatchListMutationHookResult = ReturnType<typeof useRemoveFromWatchListMutation>;
export type RemoveFromWatchListMutationResult = Apollo.MutationResult<RemoveFromWatchListMutation>;
export type RemoveFromWatchListMutationOptions = Apollo.BaseMutationOptions<RemoveFromWatchListMutation, RemoveFromWatchListMutationVariables>;
export const GetShowBySlugDocument = gql`
    query GetShowBySlug($slug: String!) {
  nextAiringEpisode(slug: $slug) {
    airingAt
    timeUntilAiring
    episodeNumber
  }
  show(slug: $slug) {
    ageRating {
      rating
      guide
    }
    addedToMainQueue
    airingAt
    bannerUrl
    description
    dislikes
    endedOn
    episodesCount
    friendlyStatus
    likes
    links(regionLocked: true) {
      value
      urlType
      platform {
        icon
        title
        name
        colour
        image
        url
      }
    }
    otherLinks {
      value
      urlType
      color
    }
    loves
    nsfw
    popularity
    popularityPercentage
    posterUrl
    relatedShows {
      posterUrl
      slug
      title
      status
      friendlyStatus
      year
      showType
      platforms {
        icon
        name
      }
      tags {
        value
        tagType
        refId
        refUrl
      }
    }
    relativePopularity
    showType
    showCategory
    slug
    startsOn
    status
    tags {
      value
    }
    title
    titleRecord {
      en
      jp
    }
    year
    youtubeTrailerUrl
  }
}
    `;

/**
 * __useGetShowBySlugQuery__
 *
 * To run a query within a React component, call `useGetShowBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetShowBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetShowBySlugQuery, GetShowBySlugQueryVariables>) {
        return Apollo.useQuery<GetShowBySlugQuery, GetShowBySlugQueryVariables>(GetShowBySlugDocument, baseOptions);
      }
export function useGetShowBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShowBySlugQuery, GetShowBySlugQueryVariables>) {
          return Apollo.useLazyQuery<GetShowBySlugQuery, GetShowBySlugQueryVariables>(GetShowBySlugDocument, baseOptions);
        }
export type GetShowBySlugQueryHookResult = ReturnType<typeof useGetShowBySlugQuery>;
export type GetShowBySlugLazyQueryHookResult = ReturnType<typeof useGetShowBySlugLazyQuery>;
export type GetShowBySlugQueryResult = Apollo.QueryResult<GetShowBySlugQuery, GetShowBySlugQueryVariables>;
export const NextAiringEpisodeDocument = gql`
    query NextAiringEpisode($slug: String!) {
  nextAiringEpisode(slug: $slug) {
    airingAt
    timeUntilAiring
    episodeNumber
  }
}
    `;

/**
 * __useNextAiringEpisodeQuery__
 *
 * To run a query within a React component, call `useNextAiringEpisodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextAiringEpisodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextAiringEpisodeQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useNextAiringEpisodeQuery(baseOptions: Apollo.QueryHookOptions<NextAiringEpisodeQuery, NextAiringEpisodeQueryVariables>) {
        return Apollo.useQuery<NextAiringEpisodeQuery, NextAiringEpisodeQueryVariables>(NextAiringEpisodeDocument, baseOptions);
      }
export function useNextAiringEpisodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextAiringEpisodeQuery, NextAiringEpisodeQueryVariables>) {
          return Apollo.useLazyQuery<NextAiringEpisodeQuery, NextAiringEpisodeQueryVariables>(NextAiringEpisodeDocument, baseOptions);
        }
export type NextAiringEpisodeQueryHookResult = ReturnType<typeof useNextAiringEpisodeQuery>;
export type NextAiringEpisodeLazyQueryHookResult = ReturnType<typeof useNextAiringEpisodeLazyQuery>;
export type NextAiringEpisodeQueryResult = Apollo.QueryResult<NextAiringEpisodeQuery, NextAiringEpisodeQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    