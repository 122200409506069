import { Badge, Button, makeStyles, Paper } from "@material-ui/core";
import { AiringStatus, Show } from "src/generated/graphql";
import { ordinalize } from "src/utils";
import { timeAgo } from "src/utils/date";
import { DetailsSubSection } from "./DetailsSubSection";
import { OtherLinksTagsList } from "./OtherLinksTagsList";
import { ShowTagsList } from "./ShowTagsList";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { RequireAuthenticated } from "src/config";
import { useWatchListManagement } from "../hooks/useWatchListManagement";

const useStyles = makeStyles((theme) => ({
  description: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  action: {
    width: "100%",
  },
}));

export function AllDetails({
  show,
  refetching,
  episodesCount,
}: {
  show: Show;
  refetching: boolean;
  episodesCount: string | number;
}) {
  const classes = useStyles();
  const { loading, addToWatchList, removeFromWatchList } =
    useWatchListManagement(show.slug);

  const addToWatchListMarkup = (
    <Button
      disabled={loading || refetching}
      variant="outlined"
      size="small"
      startIcon={<AddIcon />}
      onClick={addToWatchList}
      className={classes.action}
    >
      Add to watch list
    </Button>
  );

  const removeFromWatchListMarkup = (
    <Button
      disabled={loading || refetching}
      variant="contained"
      size="small"
      color="primary"
      startIcon={<RemoveIcon />}
      onClick={removeFromWatchList}
      className={classes.action}
    >
      Remove from watch list
    </Button>
  );

  return (
    <Paper variant="elevation" elevation={5} className={classes.description}>
      <RequireAuthenticated>
        <DetailsSubSection
          content={
            show.addedToMainQueue
              ? removeFromWatchListMarkup
              : addToWatchListMarkup
          }
        />
      </RequireAuthenticated>
      {Number(episodesCount) > 0 && (
        <DetailsSubSection
          title={"Episodes"}
          content={
            <Badge
              badgeContent={episodesCount}
              max={Number(episodesCount)}
              color="secondary"
              style={{ marginLeft: 16 }}
            />
          }
        />
      )}
      {show.links.length > 0 && (
        <DetailsSubSection
          raw
          title={`Streaming platforms (${show.links.length})`}
          content={<ShowTagsList showPlatforms show={show} />}
        />
      )}
      {show.otherLinks.length > 0 && (
        <DetailsSubSection
          raw
          title="Other links"
          content={<OtherLinksTagsList show={show} />}
        />
      )}
      <DetailsSubSection
        title={`${show.friendlyStatus} rank (${show.showType})`}
        content={`${ordinalize(show.relativePopularity)} most popular`}
      />
      <DetailsSubSection
        title=""
        progress
        content={show.popularityPercentage}
      />
      <DetailsSubSection
        title="Overall rank"
        content={`${ordinalize(show.popularity)} most popular`}
      />
      <DetailsSubSection title={"Age rating"} content={show.ageRating.rating} />
      {show.ageRating.guide && (
        <DetailsSubSection
          title={"Age rating guide"}
          content={show.ageRating.guide}
        />
      )}
      {show.tags.length > 0 && (
        <DetailsSubSection
          raw
          title={`Tags (${show.tags.length})`}
          content={<ShowTagsList allowShowAll show={show} limit={8} />}
        />
      )}
      {show.friendlyStatus && (
        <DetailsSubSection
          title={"Airing status"}
          content={show.friendlyStatus}
        />
      )}
      {show.startsOn && (
        <DetailsSubSection
          title={
            show.status === AiringStatus.Upcoming ||
            show.status === AiringStatus.ComingSoon
              ? "Coming on"
              : "Started airing"
          }
          content={new Date(show.startsOn).toLocaleDateString()}
        />
      )}
      {show.endedOn && (
        <DetailsSubSection
          title={"Finished airing"}
          content={timeAgo(show.endedOn, { capitalize: true, showDate: true })}
        />
      )}
      <DetailsSubSection title={"Age rating"} content={show.ageRating.rating} />
      <DetailsSubSection title={"Category/Type"} content={show.showType} />
      {show.titleRecord.en && (
        <DetailsSubSection title={"English"} content={show.titleRecord.en} />
      )}
      {show.titleRecord.jp && (
        <DetailsSubSection title={"Japanese"} content={show.titleRecord.jp} />
      )}
    </Paper>
  );
}
