import "./App.css";

import AppRouter from "./AppRouter";
import PlaygroundRouter from "./PlaygroundRouter";
import AuthenticationRouter from "./AuthenticationRouter";
import {
  GraphQLProvider,
  LoadingStateProvider,
  SessionProvider,
  YourAnimeThemeProvider,
} from "src/config";

import { BrowserRouter as Router } from "react-router-dom";
// import { NotFound } from "src/sections";

export function App() {
  return (
    <Router>
      <GraphQLProvider enabledGAEnvironments={["production"]}>
        <SessionProvider>
          <PlaygroundRouter />
          <AuthenticationRouter />
          <YourAnimeThemeProvider>
            <LoadingStateProvider>
              <AppRouter />
            </LoadingStateProvider>
          </YourAnimeThemeProvider>
        </SessionProvider>
      </GraphQLProvider>
    </Router>
  );
}
